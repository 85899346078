import { useEffect, useState } from "react";
import PaginationBs from "react-bootstrap/Pagination";

interface Props {
  activePage?: number;
  totalPageRange: number;
  pageRangeDisplayed: number;
  onChange: (page: number) => void;
}

export default function Pagination(props: Props) {
  const { activePage, totalPageRange, pageRangeDisplayed, onChange } = props;
  const [activePageInternal, setActivePageInternal] = useState(1);

  useEffect(() => {
    if (activePage) {
      setActivePageInternal(activePage);
    }
  }, []);

  useEffect(() => {
    if (activePage) {
      setActivePageInternal(activePage);
    }
  }, [activePage]);

  const isAvailableNumber = (number: number): boolean => {
    return number > 0 && number <= totalPageRange;
  };

  const disableNav = (number: number): boolean => {
    return number <= 0 || number > totalPageRange;
  };

  const handlerChangePage = (number: number, disable: boolean) => (_: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (disable) {
      return;
    }
    setActivePageInternal(number);
    onChange(number);
  };

  const renderFirst = () => {
    const disable = disableNav(activePageInternal - 1);

    return <PaginationBs.First disabled={disable} onClick={handlerChangePage(1, disable)} />;
  };

  const renderPrev = () => {
    const backStep = activePageInternal - 1;
    const disable = disableNav(backStep);

    return <PaginationBs.Prev disabled={disable} onClick={handlerChangePage(backStep, disable)} />;
  };

  const renderNext = () => {
    const nextStep = activePageInternal + 1;
    const disable = disableNav(nextStep);

    return <PaginationBs.Next disabled={disable} onClick={handlerChangePage(nextStep, disable)} />;
  };

  const renderLast = () => {
    const disable = disableNav(activePageInternal + 1);

    return <PaginationBs.Last disabled={disable} onClick={handlerChangePage(totalPageRange, disable)} />;
  };

  const itemList = (): number[] => {
    const numbersDisplayed = Math.min(totalPageRange, pageRangeDisplayed);

    if (numbersDisplayed < 2) {
      return [];
    }
    let countNumbersLeft = Math.ceil((numbersDisplayed - 1) / 2);
    let countNumbersRight = Math.floor((numbersDisplayed - 1) / 2);
    const leftNumbers: number[] = [];
    const rightNumbers: number[] = [];
    let i = 0;
    let k = 0;

    while (countNumbersLeft > 0 || countNumbersRight > 0) {
      i++;
      k++;

      if (countNumbersLeft) {
        const number = activePageInternal - i;
        if (isAvailableNumber(number)) {
          leftNumbers.push(number);
          --countNumbersLeft;
        } else {
          countNumbersRight += countNumbersLeft;
          countNumbersLeft = 0;
        }
      }
      if (countNumbersRight) {
        const number = activePageInternal + k;
        if (isAvailableNumber(number)) {
          rightNumbers.push(number);
          --countNumbersRight;
        } else {
          countNumbersLeft += countNumbersRight;
          countNumbersRight = 0;
        }
      }
    }

    return [...leftNumbers.reverse(), activePageInternal, ...rightNumbers];
  };

  return (
    <PaginationBs>
      {renderFirst()}
      {renderPrev()}
      {itemList().map((item) => (
        <PaginationBs.Item key={item} active={item === activePageInternal} onClick={handlerChangePage(item, false)}>
          {item}
        </PaginationBs.Item>
      ))}
      {renderNext()}
      {renderLast()}
    </PaginationBs>
  );
}
