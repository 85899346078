/* eslint-disable no-console */
import { useEffect, useState } from "react";

import type { Table } from "@tanstack/react-table";

interface Props<T> {
  instance: Table<T>;
}

const showReactTableStateStorageKey = "show_react_table_state";

export default function RenderTableStateDebug<T>({ instance }: Props<T>) {
  const [renderState, setRenderState] = useState<boolean>(false);

  useEffect(() => {
    const showReactTableState = localStorage.getItem(showReactTableStateStorageKey);
    if (showReactTableState) {
      setRenderState(showReactTableState === "1");
    }
  }, []);

  const toggleRenderState = () => {
    setRenderState((prevState) => !prevState);
    localStorage.setItem(showReactTableStateStorageKey, renderState ? "0" : "1");
  };

  return (
    <>
      <button type="button" onClick={() => console.log("instance.getState()", instance.getState())}>
        console.<strong>log</strong> react-table instance state
      </button>
      <button type="button" onClick={() => console.table(instance.getState())}>
        console.<strong>table</strong> react-table instance state
      </button>
      <div>{instance.getRowModel().rows.length} Page rows</div>
      <div>{instance.getPrePaginationRowModel().rows.length} Total rows</div>
      <div>
        {Object.keys(instance.getSelectedRowModel().rows).length} of {instance.getPreFilteredRowModel().rows.length} Total Rows Selected
      </div>
      <div>
        <button
          type="button"
          onClick={() => {
            console.log("rowSelection 1", instance.getSelectedRowModel().rows);
            // console.log(
            //   "rowSelection 2",
            //   instance
            //     .getSelectedRowModel()
            //     .rows.reduce((acc, item) => ({ ...acc, [item.id]: true }))
            // );
          }}
        >
          Log `rowSelection` state
        </button>
      </div>
      <div>
        <button type="button" onClick={() => console.log("instance.getSelectedFlatRows()", instance.getSelectedRowModel().flatRows)}>
          Log instance.getSelectedFlatRows()
        </button>
      </div>
      <hr />
      <button type="button" onClick={toggleRenderState}>
        {renderState ? "👇 Hide " : "👉 Show "} state details
      </button>
      {renderState && <pre>{JSON.stringify(instance.getState(), null, 2)}</pre>}
      <div />
    </>
  );
}
