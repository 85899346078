import createStore from "squawk-react";

import type { Territory, Vendor } from "Src/api/Dto";

export const {
  action: actionApp,
  useSquawk: useSquawkApp,
  pending: pendingApp,
  usePending: usePendingApp
} = createStore<{ signedVendors: Vendor[]; allVendors: Vendor[]; territories: Territory[] }>(
  {
    signedVendors: [],
    allVendors: [],
    territories: []
  },
  true
);
