import { useMemo } from "react";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router";
import { createColumnHelper } from "@tanstack/react-table";

import type { Vendor } from "Src/api/Dto";

import RtTable from "Src/components/Common/Table/RtTable";

import { useSquawkApp } from "Src/squawk/StoreApp";

const columnHelper = createColumnHelper<Vendor>();

export default function List() {
  const { signedVendors } = useSquawkApp();
  const history = useHistory();

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Återförsäljare"
      }),
      columnHelper.display({
        header: "Tilldela återförsäljare till bok",
        meta: {
          align: "center"
        },
        cell: (cell) => (
          <Button
            size="sm"
            onClick={() => history.push(`/publisher/books/vendor-list/assignment/${cell.row.original.vendorBrId}`, { vendor: cell.row.original })}
          >
            Böcker
          </Button>
        )
      })
    ],
    []
  );

  return (
    <RtTable
      fitToContent
      bootstrapTableProps={{
        responsive: true,
        striped: true
      }}
      useReactTableProps={{
        data: signedVendors,
        columns
      }}
    />
  );
}
