import type { ColumnDef } from "@tanstack/react-table";

import IndeterminateCheckbox from "../Defaults/IndeterminateCheckbox";

export default function RtColumnCheckbox<T>(
  renderCheckboxComponent?: (checked: boolean, indeterminate: boolean, onChange: (event: unknown) => void) => JSX.Element
): ColumnDef<T> {
  return {
    id: "custom-selection",
    size: 1, // Used when using <div /> instead of <table />, usually with virtualised tables
    meta: {
      style: { textAlign: "center" },
      className: "rt__default-col-checkbox"
    },
    header: ({ table }) => (
      <IndeterminateCheckbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => {
      if (renderCheckboxComponent) {
        return renderCheckboxComponent(row.getIsSelected(), row.getIsSomeSelected(), row.getToggleSelectedHandler());
      }

      return (
        <IndeterminateCheckbox checked={row.getIsSelected()} indeterminate={row.getIsSomeSelected()} onChange={row.getToggleSelectedHandler()} />
      );
    }
  };
}
