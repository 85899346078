import { useEffect, useState } from "react";
import { Card, Row, Spinner, Stack } from "react-bootstrap";
import FormBs from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { Helmet } from "react-helmet";
import { useError } from "@dekiru/react-error-boundary";

import type { Log } from "Src/api/Dto";

import {
  assignmentsCreated,
  assignmentsDeleted,
  assignmentsUpdated,
  contentDownloadedByAdmin,
  contentDownloadedByVendor,
  getContentUploads
} from "Src/api/KPI";

import Pagination from "Src/components/Common/Pagination";

interface LogType {
  name: string;
  description: string;
}

interface State {
  loading: boolean;
  logs: Log[];
  logTypes: LogType[];
  activePage: number;
  selectedLogName: string;
  totalPages: number;
}

export function Index() {
  const pageSize = 10;

  const [{ loading, logTypes, logs, selectedLogName, activePage, totalPages }, setState] = useState<State>({
    loading: true,
    logTypes: [],
    logs: [],
    selectedLogName: "",
    activePage: 1,
    totalPages: 0
  });

  const dispatchError = useError();

  useEffect(() => {
    start();
  }, []);

  const start = async () => {
    try {
      setState((state) => ({ ...state, loading: true }));
      const logTypes: LogType[] = [
        { name: "getContentUploads", description: "Uppladdade filer" },
        { name: "assignmentsCreated", description: "Skapade tilldelningar" },
        { name: "assignmentsDeleted", description: "Raderade tilldelningar" },
        { name: "assignmentsUpdated", description: "Uppdaterade tilldelningar" },
        { name: "contentDownloadedByVendor", description: "Filnedladdningar av återförsäljare" },
        { name: "contentDownloadedByAdmin", description: "Filnedladdningar av admin" }
      ];

      const selectedLogName = logTypes[0].name;

      setState((state) => ({ ...state, logTypes, selectedLogName }));

      await getLogs(selectedLogName);

      setState((state) => ({ ...state, loading: false }));
    } catch (error) {
      dispatchError(error);
    }
  };

  const handleLogChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const logName = event.target.value.toString();
    setState((state) => ({ ...state, selectedLogName: logName, loading: true }));
    await getLogs(logName);
    setState((state) => ({ ...state, loading: false }));
  };

  const handlePagination = (number: number) => {
    setState((state) => ({ ...state, activePage: number }));
  };

  const getLogs = async (logName: string) => {
    let logs: Log[] = [];
    switch (logName) {
      case "getContentUploads":
        logs = await getContentUploads();
        break;
      case "assignmentsCreated":
        logs = await assignmentsCreated();
        break;
      case "assignmentsDeleted":
        logs = await assignmentsDeleted();
        break;
      case "assignmentsUpdated":
        logs = await assignmentsUpdated();
        break;
      case "contentDownloadedByVendor":
        logs = await contentDownloadedByVendor();
        break;
      case "contentDownloadedByAdmin":
        logs = await contentDownloadedByAdmin();
        break;
      default:
    }
    const totalPages = Math.ceil(logs.length / 10);

    setState((state) => ({ ...state, logs, totalPages, activePage: 1 }));
  };

  const renderDropDown = () => {
    if (!logTypes.length) {
      return "";
    }

    return (
      <FormBs>
        <FormBs.Label>
          <strong>Typ</strong>
        </FormBs.Label>
        <FormBs.Select disabled={logTypes.length === 0} value={selectedLogName} onChange={(e) => handleLogChange(e)}>
          {logTypes.map((lt) => (
            <option key={lt.name} value={lt.name}>
              {lt.description}
            </option>
          ))}
        </FormBs.Select>
      </FormBs>
    );
  };

  const renderTable = (logs: Log[], activePage: number) => {
    if (logs.length === 0) {
      return "";
    }

    return (
      <>
        <Table responsive striped>
          <thead>
            <tr>
              <th>BrId</th>
              <th>Meddelande</th>
              <th>Datum</th>
            </tr>
          </thead>
          <tbody>{logs.slice((activePage - 1) * pageSize, (activePage - 1) * pageSize + pageSize).map(renderRow)}</tbody>
        </Table>
        {totalPages > 1 && (
          <Stack className="justify-content-center justify-content-md-end" direction="horizontal">
            <Pagination activePage={activePage} pageRangeDisplayed={4} totalPageRange={totalPages} onChange={(number) => handlePagination(number)} />
          </Stack>
        )}
      </>
    );
  };

  const renderRow = (log: Log) => {
    return (
      <tr key={log.logId}>
        <td>{log.brId}</td>
        <td>{log.message}</td>
        <td>{log.dateSwe}</td>
      </tr>
    );
  };

  return (
    <>
      <Helmet title="Loggar" />
      <Card className="p-4">
        {renderDropDown()}
        <br />
        <h2>Loggar </h2>
        {loading ? (
          <Row className="justify-content-center">
            <Spinner animation="border" />
          </Row>
        ) : (
          renderTable(logs, activePage)
        )}
      </Card>
    </>
  );
}
