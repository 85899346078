import { useEffect, useState } from "react";
import { Row, Spinner, Stack } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { Helmet } from "react-helmet";
import { useError } from "@dekiru/react-error-boundary";

import type { Book, Publisher } from "Src/api/Dto";

import { getBooksCount, getPublisherBooksInterval, getPublishers } from "Src/api/Admin";

import Pagination from "Src/components/Common/Pagination";

import { BookDownload } from "./BookDownload";
import { BookVendors } from "./BookVendors";
import { PublisherDropDown } from "./PublisherSearch";

interface State {
  loading: boolean;
  books: Book[];
  publishers: Publisher[];
  activePage: number;
  selectedPublisherBrId: string;
  totalPages: number;
}

export function Index() {
  const pageSize = 10;

  const [{ loading, books, publishers, selectedPublisherBrId, activePage, totalPages }, setState] = useState<State>({
    loading: true,
    books: [],
    publishers: [],
    selectedPublisherBrId: "",
    activePage: 1,
    totalPages: 0
  });

  const dispatchError = useError();

  useEffect(() => {
    start();
  }, []);

  const start = async () => {
    try {
      setState((state) => ({ ...state, loading: true }));
      const publishers = await getPublishers();
      setState((state) => ({ ...state, publishers, loading: false }));
    } catch (error) {
      dispatchError(error);
    }
  };

  const handlePublisherChange = async (key: string) => {
    const publisherBrId = key;

    setState((state) => ({ ...state, selectedPublisherBrId: publisherBrId, loading: true }));

    const [books, nrOfBooks] = await Promise.all([getPublisherBooksInterval(publisherBrId, 1, pageSize), getBooksCount(publisherBrId)]);
    const totalPages = Math.ceil(nrOfBooks / 10);

    setState((state) => ({ ...state, books, totalPages, loading: false }));
  };

  const handlePagination = async (number: number) => {
    const page = number;

    setState((state) => ({ ...state, activePage: page, loading: true }));

    const books = await getPublisherBooksInterval(selectedPublisherBrId, page, pageSize);

    setState((state) => ({ ...state, books, loading: false }));
  };

  const renderDropDown = () => {
    if (publishers.length) {
      return <PublisherDropDown publishers={publishers} onChange={handlePublisherChange} />;
    }

    return null;
  };

  const renderTable = (books: Book[], activePage: number) => {
    if (books.length === 0) {
      return "";
    }

    return (
      <>
        <Table responsive striped>
          <thead>
            <tr>
              <th>ISBN</th>
              <th>Name</th>
              <th className="text-center">Datum</th>
              <th className="text-center">Typ</th>
              <th className="text-center">Ladda ned</th>
              <th className="text-center">Återförsäljare</th>
            </tr>
          </thead>
          <tbody>
            {books.map((book) => (
              <tr key={book.isbn}>
                <td>{book.isbn}</td>
                <td>{book.title}</td>
                <td className="text-center">{book.releaseDate ? new Date(book.releaseDate).toISOString().slice(0, 10) : "Datum saknas"}</td>
                <td className="text-center">{book.bookTypeName}</td>
                <td className="text-center">
                  <BookDownload isbn={book.isbn} />
                </td>
                <td className="text-center">
                  <BookVendors brId={selectedPublisherBrId} isbn={book.isbn} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {totalPages > 1 && (
          <Stack className="justify-content-center justify-content-md-end" direction="horizontal">
            <Pagination activePage={activePage} pageRangeDisplayed={4} totalPageRange={totalPages} onChange={(number) => handlePagination(number)} />
          </Stack>
        )}
      </>
    );
  };

  return (
    <>
      <Helmet title="Böcker" />
      <Card className="p-4">
        {renderDropDown()}
        <br />
        <h2>Böcker</h2>
        {loading ? (
          <Row className="justify-content-center">
            <Spinner animation="border" />
          </Row>
        ) : (
          renderTable(books, activePage)
        )}
      </Card>
    </>
  );
}
