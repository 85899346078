import { useEffect, useState } from "react";
import { Card, Row, Spinner } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Helmet } from "react-helmet";
import { useError } from "@dekiru/react-error-boundary";

import { getBooks, getContentDownloads, getContents, getPublishers, getVendors } from "Src/api/KPI";

interface KPIType {
  name: string;
  description: string;
  value: number;
}

interface State {
  loading: boolean;
  kpi: KPIType[];
}

export function Index() {
  const [{ loading, kpi }, setState] = useState<State>({
    loading: false,
    kpi: []
  });

  const dispatchError = useError();

  const start = async () => {
    try {
      setState((state) => ({ ...state, loading: true }));

      const [publishers, vendors, books, contents, contentDownloads] = await Promise.all([
        getPublishers(),
        getVendors(),
        getBooks(),
        getContents(),
        getContentDownloads()
      ]);

      const kpi: KPIType[] = [
        { name: "Antal förlag", description: "Aktuellt antal i databasen", value: publishers },
        { name: "Antal återförsäljare", description: "Aktuellt antal i databasen", value: vendors },
        { name: "Antal böcker", description: "Aktuellt antal i databasen", value: books },
        { name: "Antal filer", description: "Aktuellt antal i databasen", value: contents },
        { name: "Antal filnedladdningar", description: "Loggade händelser (oavsett typ av användare)", value: contentDownloads }
      ];

      setState((state) => ({ ...state, kpi, loading: false }));
    } catch (error) {
      dispatchError(error);
    }
  };

  useEffect(() => {
    start();
  }, []);

  return (
    <>
      <Helmet title="KPI" />
      <Card className="p-4">
        <h2>KPI</h2>
        {loading ? (
          <Row className="justify-content-center">
            <Spinner animation="border" />
          </Row>
        ) : (
          <Table responsive striped>
            <thead>
              <tr>
                <th>Namn</th>
                <th>Beskrivning</th>
                <th className="text-center">Antal</th>
              </tr>
            </thead>
            <tbody>
              {kpi.map((k) => (
                <tr key={k.name}>
                  <td key={k.name}>{k.name}</td>
                  <td key={k.name}>{k.description}</td>
                  <td key={k.name} className="text-center">
                    {k.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Card>
    </>
  );
}
