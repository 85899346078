import { useEffect, useRef, useState } from "react";
import { Card, Row, Spinner } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Helmet } from "react-helmet";
import { Prompt, useLocation, useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useError } from "@dekiru/react-error-boundary";

import type { Book, ContentType, UploadSessionResponse } from "Src/api/Dto";
import type { SearchedBook } from "Src/components/Common/SearchBooks";
import type { DropdownOptions } from "Src/interfaces/Interfaces";

import {
  commitUploadSession,
  createUploadSessionAsync,
  deleteContent,
  getBook,
  getBookTypes,
  getContents,
  getContentTypes
} from "Src/api/Publishers";

import useUser from "Src/hooks/useUser";

import { parseQuery } from "Src/utils/QueryParser";
import { downloadAjax } from "Src/utils/Utils";

import { Form } from "./Form";
import { UploadedList } from "./UploadedList";
import { fileRequest, initialState, parseFile } from "./Utils";

export interface FormValues {
  contentTypeId: number;
  filename: string;
  fileSize: number;
}

export interface UploadState {
  progress: number;
  progressMax: number;
  loading: "delete" | "download" | "initial" | "none" | "upload";
  uploadedContentTypes: ContentType[];
  contentTypesOpt: DropdownOptions[];
  bookTypeText: string;
  formValues: FormValues;
  book: Book;
}

interface ByteRange {
  firstPosition: number;
  lastPosition: number;
}

export function Index() {
  const file = useRef<File | null>(null);
  const [state, setState] = useState<UploadState>(initialState);
  const uploadChunkSizeBytes = 1000000;
  const chunkingThresholdBytes = 1000000;
  const [isWrongFileType, setIsWrongFileType] = useState(false);
  const history = useHistory();

  const dispatchError = useError();
  const { isbn: isbnUrl } = useParams<{ isbn: string | undefined }>();
  const { state: locationState } = useLocation<{ book: Book }>();
  // react-router contains book object if user arrives here from within the webbapp
  // otherwise user arrives from the epi app which mean we have to retrieve the book object through the api
  const arrivedFromEpi = locationState ? false : true;

  const { brId, apiKey } = useUser();
  const { book, bookTypeText, formValues, progressMax, progress, loading, uploadedContentTypes, contentTypesOpt } = state;
  const { contentTypeId, fileSize, filename: _ } = formValues;

  const [selectedBook, setSelectedBook] = useState<SearchedBook | undefined>();
  const isbn = selectedBook?.isbn || isbnUrl || (parseQuery(location.search).get("isbn") as string);

  const setSelectedEpiBook = async () => {
    if (isbn && arrivedFromEpi) {
      const book = await getBook(brId, isbn);
      setSelectedBook({ isbn: book.isbn, title: book.title });
    }
  };

  useEffect(() => {
    // If we have an isbn in the URL on first load, meaning an arrival from epi, we set the selected book to that isbn
    if (isbn && arrivedFromEpi) {
      setSelectedEpiBook();
    }
  }, []);

  useEffect(() => {
    if (locationState && locationState.book) {
      setSelectedBook({ isbn: locationState.book.isbn, title: locationState.book.title });
    }
  }, [locationState]);

  const loadBook = async () => {
    let book: Book;
    if (arrivedFromEpi) {
      book = await getBook(brId, isbn);
    } else {
      // location object from react-router contains book object
      // eslint-disable-next-line prefer-destructuring
      book = locationState.book;
    }
    // Figure out if we're in upload or edit mode
    const [bookTypes, uploadedContentTypes] = await Promise.all([getBookTypes(brId), getContents(brId, isbn)]);
    let contentTypes = await getContentTypes(brId, book.bookTypeId);
    if (book.contentTypeIdRestriction && book.contentTypeIdRestriction > 0) {
      contentTypes = contentTypes.filter((c) => c.contentTypeId === book.contentTypeIdRestriction);
    }
    const bookTypesOpt: DropdownOptions[] = bookTypes.map((b) => ({
      key: b.bookTypeId.toString(),
      value: b.bookTypeId.toString(),
      text: b.name
    }));
    const contentTypesOpt: DropdownOptions[] = contentTypes.map((c) => ({
      key: c.contentTypeId.toString(),
      value: c.contentTypeId.toString(),
      text: c.name
    }));

    const updatedForm = { ...formValues, contentTypeId: parseInt(contentTypesOpt[0].value, 10) };
    const bookTypeText = bookTypesOpt.filter((b) => parseInt(b.value, 10) === book.bookTypeId)[0].text;
    setState((state) => ({ ...state, book, bookTypeText, contentTypesOpt, loading: "none", uploadedContentTypes, formValues: updatedForm }));
  };

  useEffect(() => {
    if (isbn && selectedBook !== undefined) {
      // Occurs when isbn is retrieved from a searched book
      setState(initialState);
      loadBook();
    } else {
      // Clear everything when user clears selected book from search
      setState(initialState);
    }
  }, [isbn, selectedBook]);

  const downloadBook = (book: Book, contentTypeId: number) => {
    setState((state) => ({ ...state, loading: "download" }));
    downloadAjax(`${import.meta.env.WP_ABSOLUTE_URL}/Publishers/${brId}/Books/${book.isbn}/Contents/${contentTypeId}`, book.isbn, apiKey, brId)
      .then(() => {
        setState((state) => ({ ...state, loading: "none" }));
      })
      .catch((error) => {
        const reader = new FileReader();
        reader.onload = function () {
          if (typeof reader.result !== "string") {
            return;
          }
          let errorMessage = "";
          const parsedResponse = JSON.parse(reader.result);
          if (error.status === 404) {
            errorMessage = `Fil för nedladdning saknas. Vänligen radera filen i listan "Uppladdade filer" och ladda upp den på nytt.`;
          } else {
            errorMessage = parsedResponse.Message;
          }
          dispatchError(`${errorMessage}${import.meta.env.NODE_ENV === "development" ? `\n\n${parsedResponse.Trace}` : ""}`);
        };
        reader.readAsText(error.response);
      });
  };

  const updateProgress = (progress: number, progressMax?: number, loading?: boolean) => {
    if (!progressMax) {
      setState((state) => ({ ...state, progress }));
    }
    if (progressMax) {
      setState((state) => ({ ...state, progress, progressMax }));
    }
    if (progressMax && loading) {
      setState((state) => ({ ...state, progress, progressMax, loading: loading ? "upload" : "none" }));
    }
  };

  const uploadBook = async (e: React.FormEvent<HTMLFormElement>) => {
    // Prevent form from refreshing the page on submit
    e.preventDefault();

    // BookTypeId = 3 = EPUB
    if (book.bookTypeId === 3 && uploadedContentTypes.length >= 1) {
      alert(
        "Du kan endast ha en uppladdad fil för en EPUB-titel. Vänligen ta bort den existerande filen och ladda upp igen om du vill ersätta den nuvarande"
      );

      return;
    }

    const allowedZipTypes = ["application/x-zip-compressed", "application/zip", "application/zip-compressed"];
    if (file.current !== null && (!allowedZipTypes.includes(file.current.type) || !file.current.name.endsWith(".zip"))) {
      setIsWrongFileType(true);

      return;
    }

    setState((state) => ({ ...state, loading: "upload", progress: 0, progressMax: fileSize }));

    // Delete existing content so we can "override" it
    if (uploadedContentTypes.some((contentType) => contentType.contentTypeId === contentTypeId)) {
      await deleteContent(brId, isbn, contentTypeId);
    }

    if (fileSize < chunkingThresholdBytes) {
      await fullFileUpload();
    } else {
      await chunkedFileUpload();
    }
  };

  const deleteFile = async (contentTypeId: number) => {
    setState((state) => ({ ...state, loading: "delete" }));
    await deleteContent(brId, isbn, contentTypeId);
    const uploadedContentTypes = await getContents(brId, isbn);
    setState((state) => ({ ...state, loading: "none", uploadedContentTypes }));
  };

  const updateForm = (value: number | string, name: string) => {
    const updatedForm = { ...formValues, [name]: value };
    setState((state) => ({ ...state, formValues: updatedForm }));
  };

  const updateFile = (newFile: File) => {
    const updatedForm = { ...formValues, filename: newFile.name, fileSize: newFile.size };
    setState((state) => ({ ...state, formValues: updatedForm }));
    file.current = newFile;
  };

  const fullFileUpload = async () => {
    const buffer = await parseFile(file.current, updateProgress);
    await fileRequest(
      "POST",
      `${import.meta.env.WP_ABSOLUTE_URL}/Publishers/${brId}/Books/${isbn}/Contents/${contentTypeId}`,
      buffer,
      [],
      apiKey,
      updateProgress,
      true
    ).catch((error) => dispatchError(error));
    const updatedUploadedContentTypes = await getContents(brId, isbn);

    file.current = null;
    const updatedForm = { ...formValues, filename: "Välj en fil" };

    setState((state) => ({
      ...state,
      formValues: updatedForm,
      loading: "none",
      uploadedContentTypes: updatedUploadedContentTypes,
      progress: 0,
      progressMax: 0
    }));
  };

  const createByteRanges = (rangeSize: number, fileSize: number) => {
    let firstPosition = 0;
    const byteRanges: ByteRange[] = [];
    while (firstPosition < fileSize) {
      const lastPosition = Math.min(firstPosition + rangeSize, fileSize);
      byteRanges.push({
        firstPosition,
        lastPosition
      });
      firstPosition = lastPosition;
    }

    return byteRanges;
  };

  const chunkedFileUpload = async () => {
    const uploadSessionResp = await createUploadSessionAsync(brId, isbn, contentTypeId, fileSize);

    const chunkByteRanges = createByteRanges(uploadChunkSizeBytes, fileSize);

    const chunkPromises = chunkByteRanges.map((byteRange) => {
      const blob = file?.current?.slice(byteRange.firstPosition, byteRange.lastPosition);
      const headers = [["Content-Range", `bytes ${byteRange.firstPosition}-${byteRange.lastPosition - 1}/${fileSize}`]];

      return fileRequest<UploadSessionResponse>(
        "PUT",
        `${import.meta.env.WP_ABSOLUTE_URL}/Publishers/${brId}/UploadChunk?sessionId=${uploadSessionResp.sessionId}`,
        blob,
        headers,
        apiKey,
        updateProgress
      ).then((response) => {
        setState((state) => ({ ...state, progress: response.persistedByteCount }));
      });
    });
    await Promise.all(chunkPromises);
    await commitUploadSession(brId, uploadSessionResp.sessionId);
    const updatedUploadedContentTypes = await getContents(brId, isbn);

    file.current = null;
    const updatedForm = { ...formValues, filename: "Välj en fil" };
    setState((state) => ({ ...state, formValues: updatedForm, loading: "none", uploadedContentTypes: updatedUploadedContentTypes }));
  };

  const onSelectBook = async (selectedBook: SearchedBook | undefined) => {
    setSelectedBook(selectedBook);

    if (selectedBook === undefined) {
      return;
    }
    // Change the ISBN in the URL to the selected books ISBN
    const book = await getBook(brId, selectedBook.isbn);
    if (history.location.pathname.includes("book-list")) {
      history.push(`/publisher/books/book-list/upload/${selectedBook.isbn}`, { book });
    } else {
      history.push(`/publisher/upload/${selectedBook.isbn}`, { book });
    }
  };

  const renderComponents = () => {
    if (loading === "initial") {
      return (
        <Row className="justify-content-center">
          <Spinner animation="border" />
        </Row>
      );
    }

    return (
      <>
        <Card className="p-4 mt-3">
          <Form
            book={book}
            bookTypeText={bookTypeText}
            contentTypesOpt={contentTypesOpt}
            formValues={formValues}
            isWrongFileType={isWrongFileType}
            selectedBook={selectedBook}
            setIsWrongFileType={setIsWrongFileType}
            updateFile={updateFile}
            updateForm={updateForm}
            uploadBook={uploadBook}
            uploadedContentTypes={uploadedContentTypes}
            onSelectBook={onSelectBook}
          />
          {loading === "upload" && (
            <div className="mt-4">
              <ProgressBar className="mt-3" max={progressMax} now={progress} />
            </div>
          )}
        </Card>
        {loading === "delete" || loading === "download" ? (
          <Row className="justify-content-center">
            <Spinner animation="border" />
          </Row>
        ) : (
          <UploadedList book={book} deleteContent={deleteFile} downloadBook={downloadBook} uploadedContentTypes={uploadedContentTypes} />
        )}
      </>
    );
  };

  return (
    <>
      <Helmet title="Ladda upp fil" />
      <Prompt message="Du har en pågående uppladdning. Är du säker på att du vill lämna sidan?" when={loading === "upload"} />
      {renderComponents()}
    </>
  );
}
