import { useEffect, useState } from "react";
import { Card, Row, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useError } from "@dekiru/react-error-boundary";

import type { AssignmentRequest, AssignmentResponse, Book, Vendor } from "Src/api/Dto";
import type { SearchedBook } from "Src/components/Common/SearchBooks";

import { getAssignments, getVendorBooks } from "Src/api/Publishers";

import useUser from "Src/hooks/useUser";

import { updateAssignmentRequest } from "Src/squawk/ActionsAssignmentRequest";
import { usePendingAssignmentRequest } from "Src/squawk/StoreAssignmentRequest";
import { removeDateClock, transformPricePropertiesResponse } from "Src/utils/Utils";

import { Form } from "./Form";
import { List } from "./List";

interface State {
  loading: boolean;
  assignments: AssignmentResponse[];
  books: Book[];
}

export function Index() {
  const { brId } = useUser();
  const { vendorBrId: assignmentRequestLoading } = usePendingAssignmentRequest();
  const {
    state: { vendor }
  } = useLocation<{ vendor: Vendor }>();

  const [{ loading, assignments, books }, setState] = useState<State>({
    loading: true,
    assignments: [],
    books: []
  });

  const [selectedBook, setSelectedBook] = useState<SearchedBook | undefined>();

  const dispatchError = useError();

  useEffect(() => {
    open();
  }, []);

  useEffect(() => {
    if (!assignmentRequestLoading) {
      updateBooks();
      updateAssignmentRequest({
        vendorBrId: vendor.vendorBrId,
        priceProperties: transformPricePropertiesResponse(vendor.priceProperties)
      });
    }
  }, [assignmentRequestLoading]);

  const open = async () => {
    try {
      setState((state) => ({ ...state, loading: true, books: [] }));
      setSelectedBook(undefined);

      const [books, assignments] = await Promise.all([getVendorBooks(brId, vendor.vendorBrId), getAssignments(brId, null, null, null, null, null)]);

      setState((state) => ({ ...state, loading: false, assignments, books }));
    } catch (error) {
      dispatchError(error);
    }
  };

  const onSelectBook = (selectedBook: SearchedBook | undefined) => {
    if (selectedBook?.isbn && books.some((b) => b.isbn === selectedBook.isbn)) {
      alert("Denna bok är redan tilldelad");
      const assignment = assignments.find((assignment) => assignment.isbn === selectedBook.isbn && assignment.vendorBrId === vendor.vendorBrId);

      if (assignment) {
        const assignmentRequest: AssignmentRequest = {
          isbn: assignment.isbn,
          validFrom: removeDateClock(assignment.validFrom),
          validTo: removeDateClock(assignment.validTo ?? ""),
          vendorBrId: assignment.vendorBrId,
          territoryAssignments: assignment.territoryAssignments,
          priceProperties: transformPricePropertiesResponse(vendor.priceProperties, assignment.priceProperties),
          distributionProperties: assignment.distributionProperties
        };

        updateAssignmentRequest(assignmentRequest);
      }
      setSelectedBook(selectedBook);

      return;
    }
    setSelectedBook(selectedBook);
  };

  const updateBooks = async () => {
    try {
      setState((state) => ({ ...state, loading: true }));
      const [books, assignments] = await Promise.all([getVendorBooks(brId, vendor.vendorBrId), getAssignments(brId, null, null, null, null, null)]);
      setSelectedBook(undefined);
      setState((state) => ({ ...state, loading: false, assignments, books }));
    } catch (error) {
      dispatchError(error);
    }
  };

  return (
    <Card className="p-4">
      <h2 className="pb-4">{vendor.name}</h2>
      <Form books={books} selectedBook={selectedBook} vendor={vendor} onSelectBook={onSelectBook} />
      {loading || assignmentRequestLoading ? (
        <Row className="justify-content-center">
          <Spinner animation="border" />
        </Row>
      ) : (
        <div className="pt-5">
          <List assignments={assignments} books={books} setSelectedBook={setSelectedBook} updateBooks={updateBooks} vendor={vendor} />
        </div>
      )}
    </Card>
  );
}
