/* eslint-disable */
type HeaderResolver = string | Promise<string> | (() => string) | (() => Promise<string>);

export enum RejectWith {
    string,
    xhr,
    object,
}

type HttpClientConfiguration = {
    basePath: string | null;
    withCredentials: boolean;
    xhrConfigurationCallback: ((x: XMLHttpRequest) => void) | null;
    defaultHeaders: { [name: string]: HeaderResolver };
    rejectWith: RejectWith;
};

export type ClientBuilderError = {
    readyState: number;
    response: any;
    responseText: string;
    responseType: XMLHttpRequestResponseType;
    responseURL: string;
    status: number;
    statusText: string;
};

export default (() => {
    const config: HttpClientConfiguration = {
        basePath: null,
        withCredentials: false,
        xhrConfigurationCallback: null,
        defaultHeaders: {},
        rejectWith: RejectWith.string,
    };

    const isRelative = (url: string) => !url.match(/^https?:\/\//);

    const ensureSlash = (url: string) => url + (url.endsWith("/") ? "" : "/");

    const getBasePath = () => {
        if (config.basePath) {
            return config.basePath;
        }
        const base = document.querySelector("base") as HTMLBaseElement;
        const absoluteUrl = document.querySelector("meta[name='absolute-url']") as HTMLMetaElement;
        if (absoluteUrl) {
            return absoluteUrl.content;
        } else {
            return base?.href ?? "";
        }
    };

    return {
        setRejectWith: (rejectWith: RejectWith) => {
            config.rejectWith = rejectWith;
        },
        /** @deprecated Use setRejectWith instead */
        withXhr: () => {
            config.rejectWith = RejectWith.xhr;
        },
        withCredentials: (setting: boolean = true) => {
            config.withCredentials = setting;
        },
        clearDefaultHeaders: () => {
            config.defaultHeaders = {};
        },
        getDefaultHeader: (name: string) => {
            config.defaultHeaders[name];
        },
        setDefaultHeaders: (headers: { [name: string]: HeaderResolver }) => {
            for (const key in headers) {
                config.defaultHeaders[key] = headers[key];
            }
        },
        setXhrConfigurationCallback(callback: null | ((x: XMLHttpRequest) => void)) {
            config.xhrConfigurationCallback = callback;
        },
        setBasePath: (path: string | null) => {
            config.basePath = path;
        },
        getBasePath,
        resolveUrl: (url: string) => {
            if (!isRelative(url)) {
                return url;
            }
            const base = getBasePath() ?? window.location.origin;

            const absoluteBase = isRelative(base) ? new URL(base.replace(/^\//, ""), ensureSlash(origin)).href : base;

            return new URL(url.replace(/^\//, ""), ensureSlash(absoluteBase)).href;
        },
        httpFetch: async <T>(verb: string, path: string, body?: any, headers?: { [name: string]: HeaderResolver }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<T> => {
            return new Promise<T>(async (resolve, reject) => {
                const xhr: XMLHttpRequest = new XMLHttpRequest();
                config.xhrConfigurationCallback?.(xhr);

                if (uploadEvent) {
                    xhr.upload.addEventListener("abort", uploadEvent);
                    xhr.upload.addEventListener("error", uploadEvent);
                    xhr.upload.addEventListener("load", uploadEvent);
                    xhr.upload.addEventListener("loadend", uploadEvent);
                    xhr.upload.addEventListener("loadstart", uploadEvent);
                    xhr.upload.addEventListener("progress", uploadEvent);
                    xhr.upload.addEventListener("timeout", uploadEvent);
                }

                xhr.addEventListener("readystatechange", () => {
                    if (xhr.readyState !== XMLHttpRequest.DONE) {
                        return;
                    }
                    if (xhr.status < 300) {
                        try {
                            if (xhr.status === 200 && !!xhr.responseText) {
                                const contentType = xhr.getResponseHeader("Content-Type");
                                if (contentType && contentType.toLowerCase().indexOf("application/json") > -1) {
                                    resolve(JSON.parse(xhr.responseText));
                                } else {
                                    resolve((xhr.responseText as unknown) as T);
                                }
                            } else {
                                resolve((undefined as unknown) as T);
                            }
                        } catch (ex) {
                            reject(ex);
                        }
                    } else {
                        if (returnXhr || config.rejectWith === RejectWith.xhr) {
                            reject(xhr);
                        } else if (config.rejectWith === RejectWith.object) {
                            const { readyState, response, responseText, responseType, responseURL, status, statusText } = xhr;
                            reject({
                                readyState,
                                response,
                                responseText,
                                responseType,
                                responseURL,
                                status,
                                statusText,
                            });
                        } else {
                            reject(xhr.responseText);
                        }
                    }
                });

                xhr.open(verb, path, true);
                xhr.withCredentials = config.withCredentials;

                for (const name in config.defaultHeaders) {
                    // Do not override request header with default header
                    if (headers && headers[name]) {
                        continue;
                    }

                    let header = config.defaultHeaders[name];
                    if (typeof header == "function") {
                        header = header();
                    }

                    xhr.setRequestHeader(name, await Promise.resolve(header));
                }

                if (headers) {
                    for (const name in headers) {
                        let header = headers[name];
                        if (typeof header == "function") {
                            header = header();
                        }
                        xhr.setRequestHeader(name, await Promise.resolve(header));
                    }
                }
                if (body) {
                    if (body instanceof Blob) {
                        const fd = new FormData();
                        fd.append("file", body);
                        xhr.send(fd);
                    } else if (body instanceof FormData) {
                        xhr.send(body);
                    } else {
                        xhr.setRequestHeader("Content-Type", "application/json");
                        xhr.send(JSON.stringify(body));
                    }
                } else {
                    xhr.send();
                }
            });
        },
    };
})();
