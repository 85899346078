/* eslint-disable */
/* eslint-disable prettier/prettier */
// DO NOT EDIT, THIS CODE IS TOOL GENERATED
import _client from "./HttpClient";
import * as Enums from "./Enums";
import * as DTO from "./Dto";
export const deleteBook = async (isbn: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<any> => {
    return _client.httpFetch<any>('DELETE', _client.resolveUrl(`/Admin/Books/${isbn}`), null, $headers, uploadEvent, returnXhr);
};
export const getAssignmentsValidTo = async (validTo: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.VendorAssignments[]> => {
    return _client.httpFetch<DTO.VendorAssignments[]>('GET', _client.resolveUrl(`/Admin/Assignments/${validTo}`), null, $headers, uploadEvent, returnXhr);
};
export const getBook = async (isbn: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Book> => {
    return _client.httpFetch<DTO.Book>('GET', _client.resolveUrl(`/Admin/Books/${isbn}`), null, $headers, uploadEvent, returnXhr);
};
export const getBooks = async ($headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Book[]> => {
    return _client.httpFetch<DTO.Book[]>('GET', _client.resolveUrl(`/Admin/Books`), null, $headers, uploadEvent, returnXhr);
};
export const getBooksCount = async (publisherBrId: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<number> => {
    return _client.httpFetch<number>('GET', _client.resolveUrl(`/Admin/${publisherBrId}/Books/Count`), null, $headers, uploadEvent, returnXhr);
};
export const getBookTypes = async ($headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.BookType[]> => {
    return _client.httpFetch<DTO.BookType[]>('GET', _client.resolveUrl(`/Admin/BookTypes`), null, $headers, uploadEvent, returnXhr);
};
export const getContent = async (isbn: string, contentTypeId: number, range: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<void> => {
    return _client.httpFetch<void>('GET', _client.resolveUrl(`/Admin/Books/${isbn}/Contents/${contentTypeId}`), range, $headers, uploadEvent, returnXhr);
};
export const getContentIdentifier = async (vendorBrId: string, isbn: string, contentTypeId: number, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.ContentIdentifier> => {
    return _client.httpFetch<DTO.ContentIdentifier>('GET', _client.resolveUrl(`/Admin/Vendors/${vendorBrId}/Books/${isbn}/Contents/${contentTypeId}/Identifier`), null, $headers, uploadEvent, returnXhr);
};
export const getContents = async (isbn: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.ContentType[]> => {
    return _client.httpFetch<DTO.ContentType[]>('GET', _client.resolveUrl(`/Admin/Books/${isbn}/Contents`), null, $headers, uploadEvent, returnXhr);
};
export const getContentTypes = async (bookTypeId: number, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.ContentType[]> => {
    return _client.httpFetch<DTO.ContentType[]>('GET', _client.resolveUrl(`/Admin/BookTypes/${bookTypeId}/ContentTypes`), null, $headers, uploadEvent, returnXhr);
};
export const getMarkets = async ($headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Market[]> => {
    return _client.httpFetch<DTO.Market[]>('GET', _client.resolveUrl(`/Admin/Markets`), null, $headers, uploadEvent, returnXhr);
};
export const getPublisher = async (publisherBrId: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Publisher> => {
    return _client.httpFetch<DTO.Publisher>('GET', _client.resolveUrl(`/Admin/Publishers/${publisherBrId}`), null, $headers, uploadEvent, returnXhr);
};
export const getPublisherBookAssignments = async (publisherBrId: string, isbn: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.AssignmentResponse[]> => {
    return _client.httpFetch<DTO.AssignmentResponse[]>('GET', _client.resolveUrl(`/Admin/${publisherBrId}/Books/${isbn}/Assignments`), null, $headers, uploadEvent, returnXhr);
};
export const getPublisherBooksInterval = async (publisherBrId: string, pageNumber: number, pageSize: number, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Book[]> => {
    return _client.httpFetch<DTO.Book[]>('GET', _client.resolveUrl(`/Admin/${publisherBrId}/Books/${pageNumber}/${pageSize}`), null, $headers, uploadEvent, returnXhr);
};
export const getPublisherBookVendors = async (publisherBrId: string, isbn: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Vendor[]> => {
    return _client.httpFetch<DTO.Vendor[]>('GET', _client.resolveUrl(`/Admin/${publisherBrId}/Books/${isbn}/Vendors`), null, $headers, uploadEvent, returnXhr);
};
export const getPublishers = async ($headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Publisher[]> => {
    return _client.httpFetch<DTO.Publisher[]>('GET', _client.resolveUrl(`/Admin/Publishers`), null, $headers, uploadEvent, returnXhr);
};
export const getSignedAgreements = async (signedDate: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.VendorSignedAgreements[]> => {
    return _client.httpFetch<DTO.VendorSignedAgreements[]>('GET', _client.resolveUrl(`/Admin/SignedAgreements/${signedDate}`), null, $headers, uploadEvent, returnXhr);
};
export const getVendor = async (vendorBrId: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Vendor> => {
    return _client.httpFetch<DTO.Vendor>('GET', _client.resolveUrl(`/Admin/Vendors/${vendorBrId}`), null, $headers, uploadEvent, returnXhr);
};
export const getVendors = async ($headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Vendor[]> => {
    return _client.httpFetch<DTO.Vendor[]>('GET', _client.resolveUrl(`/Admin/Vendors`), null, $headers, uploadEvent, returnXhr);
};
export const triggerNotification = async (isbn: string, event: Enums.EventType, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<any> => {
    return _client.httpFetch<any>('GET', _client.resolveUrl(`/Admin/Trigger/${isbn}/${event}`), null, $headers, uploadEvent, returnXhr);
};
