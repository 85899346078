import type { Territory, Vendor } from "Src/api/Dto";

import { getTerritories, getVendors, getVendorsWithSignedAgreement } from "Src/api/Publishers";

import { getSessionStorage } from "Src/utils/Utils";

import { actionApp, pendingApp } from "./StoreApp";

export const loadAll = actionApp(async (_store, brId: string) => {
  pendingApp("allVendors", true);
  let v = getSessionStorage<Vendor[]>("allVendors");
  let t = getSessionStorage<Territory[]>("territories");
  const promiseArr: Promise<Territory[] | Vendor[]>[] = [getVendorsWithSignedAgreement(brId)];
  if (!v) {
    promiseArr.push(getVendors(brId));
  }
  if (!t) {
    promiseArr.push(getTerritories(brId));
  }

  const [signedVendors, vendors, territories] = (await Promise.all(promiseArr)) as [Vendor[], Vendor[], Territory[]];

  if (!v) {
    v = vendors;
    sessionStorage.setItem("allVendors", JSON.stringify(vendors));
  }
  if (!t) {
    t = territories;
    sessionStorage.setItem("territories", JSON.stringify(territories));
  }

  pendingApp("allVendors", false);

  return {
    signedVendors,
    allVendors: v,
    territories: t
  };
});

export const loadSignedVendors = actionApp(async (_, brId: string) => {
  const signedVendors = await getVendorsWithSignedAgreement(brId);

  return {
    signedVendors
  };
});
