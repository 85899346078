import { useEffect, useState } from "react";
import { Row, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { BsDownload, BsFiles } from "react-icons/bs";
import { useError } from "@dekiru/react-error-boundary";

import type { ContentType } from "Src/api/Dto";

import { getContents } from "Src/api/Admin";

import useToggle from "Src/hooks/useToggle";
import useUser from "Src/hooks/useUser";

import { downloadAjax } from "Src/utils/Utils";

interface Props {
  isbn: string;
}

interface State {
  loading: boolean;
  bookFormats: ContentType[];
}

export function BookDownload({ isbn }: Props) {
  const [{ loading, bookFormats }, setState] = useState<State>({
    loading: true,
    bookFormats: []
  });

  const [modalOpen, toggleModalOpen] = useToggle();
  const { apiKey } = useUser();
  const dispatchError = useError();

  useEffect(() => {
    if (!modalOpen) {
      return;
    }
    getBookInfo();
  }, [modalOpen]);

  const getBookInfo = async () => {
    try {
      setState((state) => ({ ...state, loading: true }));
      const bookFormats = await getContents(isbn);
      setState((state) => ({ ...state, bookFormats, loading: false }));
    } catch (error) {
      dispatchError(error);
    }
  };

  const downloadBook = (isbn: string, contentTypeId: number) => {
    setState((state) => ({ ...state, loading: true }));

    downloadAjax(`${import.meta.env.WP_ABSOLUTE_URL}/Admin/Books/${isbn}/Contents/${contentTypeId}`, isbn, apiKey)
      .then(() => {
        setState((state) => ({ ...state, loading: false }));
      })
      .catch((error) => {
        dispatchError(error);
      });
  };

  return (
    <>
      <Button size="sm" onClick={toggleModalOpen}>
        <BsFiles />
      </Button>
      <Modal show={modalOpen} size="lg">
        <Modal.Header>
          <Modal.Title>Nedladdningsbara format för {isbn}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Row className="justify-content-center">
              <Spinner animation="border" />
            </Row>
          ) : (
            <Table responsive striped>
              <thead>
                <tr>
                  <th>Namn</th>
                  <th>Beskrivning</th>
                  <th>MIME-typ</th>
                  <th>Extension</th>
                  <th className="text-center">Ladda ned</th>
                </tr>
              </thead>
              <tbody>
                {bookFormats.map((fmt) => (
                  <tr key={fmt.contentTypeId}>
                    <td>{fmt.name}</td>
                    <td>{fmt.description}</td>
                    <td>{fmt.mimeType}</td>
                    <td>{fmt.fileExtension}</td>
                    <td className="text-center">
                      <Button size="sm" onClick={() => downloadBook(isbn, fmt.contentTypeId)}>
                        <BsDownload />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModalOpen}>
            Stäng
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
