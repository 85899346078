import type { Table as ReactTableProps } from "@tanstack/react-table";

import DebouncedInput from "./DebouncedInput";

interface Props<T> {
  reactTableInstance: ReactTableProps<T>;
  disabled?: boolean;
}

export default function RtGlobalFilter<T>({ reactTableInstance: instance, disabled }: Props<T>) {
  if (instance.options.enableFilters === false) {
    return null;
  }

  return (
    <DebouncedInput
      disabled={disabled ?? instance.options.data.length === 0}
      placeholder="Sök i alla kolumner"
      value={instance.getState().globalFilter ?? ""}
      onChange={(value) => instance.setGlobalFilter(String(value))}
    />
  );
}
