import { useEffect, useRef } from "react";

import type { HTMLProps } from "react";

export default function IndeterminateCheckbox({ indeterminate, className = "", ...rest }: HTMLProps<HTMLInputElement> & { indeterminate?: boolean }) {
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return <input ref={ref} className={`${className}`} type="checkbox" {...rest} />;
}
