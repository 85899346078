/* eslint-disable */
/* eslint-disable prettier/prettier */
// DO NOT EDIT, THIS CODE IS TOOL GENERATED
import _client from "./HttpClient";
import * as Enums from "./Enums";
import * as DTO from "./Dto";
export const assignmentsCreated = async ($headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Log[]> => {
    return _client.httpFetch<DTO.Log[]>('GET', _client.resolveUrl(`/KPI/AssignmentsCreated`), null, $headers, uploadEvent, returnXhr);
};
export const assignmentsDeleted = async ($headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Log[]> => {
    return _client.httpFetch<DTO.Log[]>('GET', _client.resolveUrl(`/KPI/AssignmentsDeleted`), null, $headers, uploadEvent, returnXhr);
};
export const assignmentsUpdated = async ($headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Log[]> => {
    return _client.httpFetch<DTO.Log[]>('GET', _client.resolveUrl(`/KPI/AssignmentsUpdated`), null, $headers, uploadEvent, returnXhr);
};
export const contentDownloadedByAdmin = async ($headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Log[]> => {
    return _client.httpFetch<DTO.Log[]>('GET', _client.resolveUrl(`/KPI/ContentDownloadedByAdmin`), null, $headers, uploadEvent, returnXhr);
};
export const contentDownloadedByVendor = async ($headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Log[]> => {
    return _client.httpFetch<DTO.Log[]>('GET', _client.resolveUrl(`/KPI/ContentDownloadedByVendor`), null, $headers, uploadEvent, returnXhr);
};
export const getBooks = async ($headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<number> => {
    return _client.httpFetch<number>('GET', _client.resolveUrl(`/KPI/Books`), null, $headers, uploadEvent, returnXhr);
};
export const getContentDownloads = async ($headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<number> => {
    return _client.httpFetch<number>('GET', _client.resolveUrl(`/KPI/ContentDownloads`), null, $headers, uploadEvent, returnXhr);
};
export const getContents = async ($headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<number> => {
    return _client.httpFetch<number>('GET', _client.resolveUrl(`/KPI/Contents`), null, $headers, uploadEvent, returnXhr);
};
export const getContentUploads = async ($headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Log[]> => {
    return _client.httpFetch<DTO.Log[]>('GET', _client.resolveUrl(`/KPI/ContentUploads`), null, $headers, uploadEvent, returnXhr);
};
export const getPublishers = async ($headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<number> => {
    return _client.httpFetch<number>('GET', _client.resolveUrl(`/KPI/Publishers`), null, $headers, uploadEvent, returnXhr);
};
export const getVendors = async ($headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<number> => {
    return _client.httpFetch<number>('GET', _client.resolveUrl(`/KPI/Vendors`), null, $headers, uploadEvent, returnXhr);
};
