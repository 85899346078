import type { UploadState } from "./Index";

export const parseFile = (
  file: File | null,
  updateProgress: (progress: number, progressMax?: number, loading?: boolean) => void
): Promise<string> => {
  // Always return a Promise
  return new Promise((resolve, reject) => {
    let content = "";
    const reader = new FileReader();
    reader.onloadstart = (e: ProgressEvent) => {
      // First we have to load the file into the browser, and then send. So we'll process the file twice
      // this.setState({ progress: 0, progressMax: e.total * 2 });
      updateProgress(0, e.total * 2);
    };
    reader.onprogress = (e: ProgressEvent) => {
      // this.setState({ progress: e.loaded });
      updateProgress(e.loaded);
    };
    // Wait till complete
    reader.onloadend = (e: ProgressEvent<FileReader>) => {
      content = e?.target?.result as string;
      resolve(content);
    };
    // Make sure to handle error states
    reader.onerror = (e: ProgressEvent<FileReader>) => {
      // this.setState({ progress: 0, progressMax: 0, loading: false });
      updateProgress(0, 0, false);
      reject(e);
    };
    reader.readAsArrayBuffer(file as Blob);
  });
};

export const fileRequest = <T>(
  method: string,
  url: string,
  buffer: Blob | string | undefined,
  headers: string[][],
  apiKey: string,
  updateProgress: (progress: number, progressMax?: number, loading?: boolean) => void,
  showProgress = false
): Promise<T> => {
  // const $this = this;

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url);
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        const contentType = xhr.getResponseHeader("Content-Type");
        if (contentType && contentType.toLowerCase().indexOf("application/json") > -1) {
          resolve(JSON.parse(xhr.response) as T);
        } else {
          resolve(xhr.response as T);
        }
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = function () {
      // $this.setState({
      //   loading: false,
      //   progress: 0,
      //   progressMax: 0
      // });
      updateProgress(0, 0, false);

      reject({
        status: this.status,
        statusText: xhr.statusText
      });
    };
    xhr.upload.onprogress = (e: ProgressEvent) => {
      // Remember that we're parsing the file twice, we're on the second leg now
      // $this.setState({ progress: e.loaded + e.total });

      if (showProgress) {
        updateProgress(e.loaded + e.total);
      }
    };
    xhr.setRequestHeader("Ocp-Apim-Subscription-Key", apiKey);
    headers.forEach((h) => xhr.setRequestHeader(h[0], h[1]));
    xhr.send(buffer);
  });
};

export const initialState: UploadState = {
  bookTypeText: "",
  contentTypesOpt: [],
  loading: "none",
  progress: 0,
  progressMax: 0,
  uploadedContentTypes: [],
  formValues: {
    contentTypeId: 0,
    filename: "Välj en fil",
    fileSize: 0
  },
  book: {
    author: "",
    bookTypeId: 0,
    bookTypeName: "",
    contentTypeIdRestriction: null,
    description: "",
    isbn: "",
    publisherBrId: "",
    releaseDate: null,
    title: ""
  }
};
