import { useState } from "react";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useHistory, useLocation } from "react-router-dom";
import { useError } from "@dekiru/react-error-boundary";

import type { Agreement, SignedAgreementRequest, SignedAgreementResponse, Vendor, VendorSettingRequest } from "Src/api/Dto";

import { AgreementType } from "Src/api/Enums";
import { createSignedAgreement, updateSignedAgreement } from "Src/api/Publishers";

import useUser from "Src/hooks/useUser";

import { loadSignedVendors } from "Src/squawk/ActionsApp";

import { PickAgreement } from "./PickAgreement";
import { VendorSettings } from "./VendorSettings";

interface Props {
  signedAgreement: SignedAgreementResponse | undefined;
  vendorAgreements: Agreement[];
  vendor: Vendor;
  reloadVendors: () => void;
  mode: "create" | "edit";
}

interface State {
  loading: boolean;
  acceptAgreement: boolean;
  vendorSettings: VendorSettingRequest[];
  chosenAgreement: AgreementType;
}

export function CreateEditAgreement() {
  const history = useHistory();
  const {
    state: { mode, vendor, signedAgreement, vendorAgreements }
  } = useLocation<Props>();

  const { brId } = useUser();
  const dispatchError = useError();
  const vSettings =
    mode === "create"
      ? vendor.vendorSettings?.map((vendorSettings) => ({ ...vendorSettings, value: vendorSettings.value ?? "" }))
      : signedAgreement?.vendorSettings?.map((vendorSetting) => ({ ...vendorSetting, value: vendorSetting.value ?? "" }));

  const [{ loading, acceptAgreement, chosenAgreement, vendorSettings }, setState] = useState<State>({
    loading: false,
    acceptAgreement: false,
    chosenAgreement: AgreementType.Standard,
    vendorSettings: vSettings ?? []
  });

  const confirmSignAgreement = async () => {
    try {
      setState((state) => ({ ...state, loading: true }));

      const signedAgreementRequest: SignedAgreementRequest = {
        agreementType: mode === "create" ? chosenAgreement : (signedAgreement?.agreementType as AgreementType),
        publisherBrId: brId,
        signedDate: new Date().toISOString(),
        vendorBrId: vendor.vendorBrId,
        vendorSettings
      };

      if (mode === "create") {
        await createSignedAgreement(brId, signedAgreementRequest);
      }

      if (mode === "edit") {
        await updateSignedAgreement(brId, signedAgreementRequest);
      }

      await loadSignedVendors(brId);

      setState((state) => ({ ...state, loading: false }));
      history.push("/publisher/vendors");
    } catch (error) {
      dispatchError(error);
    }
  };

  const handleChangeSetting = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedVendorSettings = vendorSettings.map((vendorSetting) => {
      if (vendorSetting.name === e.target.name) {
        return { ...vendorSetting, value: e.target.value };
      }

      return vendorSetting;
    });
    setState((state) => ({ ...state, vendorSettings: updatedVendorSettings }));
  };

  const handleAgreementType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((state) => ({ ...state, chosenAgreement: parseInt(e.target.value) }));
  };

  return (
    <Container className="ms-0">
      <Row>
        <Col md="9">
          <Card className="p-4">
            <h2 className="pb-4">
              {mode === "create" ? "Välj avtal för" : "Egenskaper"} {vendor.name}
            </h2>
            {loading ? (
              <Row className="justify-content-center">
                <Spinner animation="border" />
              </Row>
            ) : (
              <Form>
                {mode === "create" && (
                  <PickAgreement
                    handleAgreementType={handleAgreementType}
                    signedAgreement={signedAgreement}
                    vendor={vendor}
                    vendorAgreements={vendorAgreements}
                  />
                )}
                <VendorSettings handleChangeSetting={handleChangeSetting} vendor={vendor} vendorSettings={vendorSettings} />
                {mode === "create" && (
                  <Form.Check
                    checked={acceptAgreement}
                    className="pt-1"
                    label="Jag godkänner härmed ovanstående avtal"
                    type="checkbox"
                    onClick={() => setState((state) => ({ ...state, acceptAgreement: !state.acceptAgreement }))}
                  />
                )}
                <Button className="mt-3" disabled={(!acceptAgreement && mode === "create") || loading} onClick={confirmSignAgreement}>
                  {mode === "create" ? "GODKÄNN" : "Uppdatera"}
                </Button>
              </Form>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
