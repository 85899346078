import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { appInsightsInit } from "@dekiru/app-insights";

import HttpClient, { RejectWith } from "./api/HttpClient";
import Routes from "./routes/Routes";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "./css/Site.css";
import "@bokinfo/bokinfo-ui/dist/css/bokinfo/main.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "@dekiru/debug-panel/dist/debug-panel.css";

HttpClient.setRejectWith(RejectWith.object);

const disableAppInsightsStorage = localStorage.getItem("debug_disable_app_insights");
const disableAppInsights = disableAppInsightsStorage === "1" && import.meta.env.NODE_ENV === "development";

const {
  appInsights,
  reactPlugin: _reactPlugin,
  TrackPageView
} = appInsightsInit({
  disableAppInsights,
  instrumentationKey: import.meta.env.APP_INSIGHTS_KEY
});

export { appInsights };

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <TrackPageView />
      {Routes}
    </BrowserRouter>
  </StrictMode>,
  document.querySelector("#root")
);
