import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

import type { Agreement, SignedAgreementResponse, Vendor } from "Src/api/Dto";

import { ShowAgreementText } from "./ShowAgreementText";

interface Props {
  signedAgreement?: SignedAgreementResponse | undefined;
  vendorAgreements: Agreement[];
  vendor: Vendor;
  handleAgreementType: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function PickAgreement({ vendor, signedAgreement, vendorAgreements, handleAgreementType }: Props) {
  return (
    <Table responsive striped>
      <thead>
        <tr>
          <th className="text-center">Avtal</th>
          <th>Innehåll</th>
          <th>Beskrivning</th>
        </tr>
      </thead>
      <tbody>
        {vendorAgreements.map((agreement) => (
          <tr key={agreement.name}>
            <td className="text-center">
              <Form.Check name="agreementType" type="radio" value={agreement.agreementType} onChange={handleAgreementType} />
            </td>
            <td>
              <ShowAgreementText agreement={agreement} signedAgreement={signedAgreement} vendor={vendor} />
            </td>
            <td>{agreement.description}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
