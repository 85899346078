import Button from "react-bootstrap/Button";

import { UserType } from "Src/api/Enums";

import useUser from "Src/hooks/useUser";

/**
 * Switch between vendor and publisher user during development
 * @returns
 */
export default function SwitchUser() {
  const user = useUser();
  const fixedPublisherId = "d7e3f469-f79e-45f5-9169-e7cfe3f61001"; // Albert Bonniers Förlag
  const fixedAdminId = "d7e3f469-f79e-45f5-9169-e7cfe3f62823"; // Admin Berne

  const switchUser = (userType: UserType) => {
    const switchId = userType === UserType.Publisher ? fixedPublisherId : fixedAdminId;
    const baseUrl = window.location.origin;
    window.location.href = `${baseUrl}\\?Key=${switchId}`;
  };

  if (import.meta.env.NODE_ENV !== "development") {
    return null;
  }

  return (
    <div>
      <p>
        <strong>Växla användare</strong>
      </p>
      <Button disabled={user.type === UserType.Publisher} variant="secondary" onClick={() => switchUser(UserType.Publisher)}>
        Förlag
      </Button>
      <Button disabled={user.type === UserType.Admin} variant="secondary" onClick={() => switchUser(UserType.Admin)}>
        Admin
      </Button>
    </div>
  );
}
