import type { Column } from "@tanstack/react-table";

import RtLoadingRow from "./RtLoadingRow";

interface Props<T> {
  visibleColumns: Column<T>[];
  renderOverlay: boolean;
}

/**
 * Loader shown when there is no initial/previous data. Usually occurs during initial/first rendering of the table
 *
 * @export
 * @template T
 * @param {Props<T>} { visibleColumns }
 * @return {*}
 */
export default function RtLoadingBody<T>({ visibleColumns, renderOverlay }: Props<T>) {
  return (
    <tbody style={{ position: "relative" }}>
      <tr>
        <td align="center" colSpan={visibleColumns.length}>
          <br />
          <br />
          <br />
        </td>
      </tr>
      <RtLoadingRow renderOverlay={renderOverlay} visibleColumns={visibleColumns} />
    </tbody>
  );
}
