import { Spinner } from "react-bootstrap";

import type { Column } from "@tanstack/react-table";

interface Props<T> {
  visibleColumns: Column<T>[];
  renderOverlay?: boolean;
}

/**
 * Table row used to show a loader overlay covering a table body only.
 * The table body must have position "relative" for the overlay to cover properly.
 *
 * @export
 * @template T
 * @param {Props<T>} { visibleColumns }
 * @return {*}
 */
export default function RtLoadingRow<T>({ visibleColumns, renderOverlay }: Props<T>) {
  return (
    <tr
      style={{
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 1000
      }}
    >
      <td colSpan={visibleColumns.length} style={{ border: "none" }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...(renderOverlay && {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              borderRadius: "8px"
            })
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Laddar...</span>
          </Spinner>
        </div>
      </td>
    </tr>
  );
}
