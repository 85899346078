import { useEffect, useRef, useState } from "react";
import { InputGroup } from "react-bootstrap";
import FormBs from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";

import type { Option } from "react-bootstrap-typeahead/types/types";
import type { Territory, TerritoryAssignment } from "Src/api/Dto";

import { updateAssignmentRequestProp } from "Src/squawk/ActionsAssignmentRequest";
import { useSquawkApp } from "Src/squawk/StoreApp";

interface State {
  selectedTerritories: Territory[];
  checkedRadio: "territory" | "worldwide";
  openMenu: boolean;
}

interface Props {
  territoryAssignments: TerritoryAssignment[] | undefined;
}

export function Territories({ territoryAssignments }: Props) {
  const typeaheadRef = useRef(null);

  const [{ selectedTerritories, checkedRadio, openMenu }, setState] = useState<State>({
    selectedTerritories: [],
    checkedRadio: "territory",
    openMenu: false
  });

  const { territories } = useSquawkApp();

  useEffect(() => {
    if (territoryAssignments) {
      const existingTerritories = territories.filter((territory) =>
        territoryAssignments.some((assignment) => assignment.territoryCode === territory.territoryCode)
      );
      if (existingTerritories.find((territory) => territory.territoryCode === "Worldwide")) {
        setState((state) => ({ ...state, selectedTerritories: existingTerritories, checkedRadio: "worldwide" }));
      } else {
        setState((state) => ({ ...state, selectedTerritories: existingTerritories, checkedRadio: "territory" }));
      }
    }
  }, [territoryAssignments]);

  const worldwide = territories.find((territory) => {
    return territory.territoryCode.toLowerCase() === "worldwide";
  });

  const territoriesWOWorlwide = territories.filter((territory) => {
    return territory.territoryCode.toLowerCase() !== "worldwide";
  });

  const handleOnChangeTypeahead = (selected: Option[]) => {
    setState((state) => ({ ...state, selectedTerritories: selected as Territory[], openMenu: true }));
    const selectedValues = selected.map((item) => (typeof item === "object" ? item.territoryCode : item));
    updateAssignmentRequestProp(selectedValues as string[], "territoryAssignments");
  };

  const handleActive = (activeRadio: "territory" | "worldwide") => {
    if (activeRadio === "worldwide" && worldwide !== undefined) {
      setState((state) => ({ ...state, selectedTerritories: [worldwide], checkedRadio: activeRadio }));
      updateAssignmentRequestProp([worldwide.territoryCode] as string[], "territoryAssignments");
    } else {
      setState((state) => ({ ...state, selectedTerritories: [], checkedRadio: activeRadio }));
      updateAssignmentRequestProp([], "territoryAssignments");
    }
  };

  return (
    <FormBs.Group className="mt-3">
      <FormBs.Label>
        <strong>Territory</strong>
      </FormBs.Label>
      <FormBs>
        <FormBs.Check
          inline
          checked={checkedRadio === "territory"}
          id="territory"
          label="Territory"
          name="radio"
          type="radio"
          onClick={() => handleActive("territory")}
        />
        <FormBs.Check
          inline
          checked={checkedRadio === "worldwide"}
          id="worldwide"
          label="Worldwide"
          name="radio"
          type="radio"
          onClick={() => handleActive("worldwide")}
        />
      </FormBs>
      <InputGroup>
        <Typeahead
          ref={typeaheadRef}
          flip
          multiple
          disabled={checkedRadio === "worldwide"}
          id="territory-typeahead"
          labelKey="territoryCode"
          open={openMenu}
          options={territoriesWOWorlwide}
          placeholder="Territory"
          selected={selectedTerritories}
          onBlur={() => {
            setState((state) => ({ ...state, openMenu: false }));
          }}
          onChange={handleOnChangeTypeahead}
          onFocus={() => {
            setState((state) => ({ ...state, openMenu: true }));
          }}
        />
      </InputGroup>
    </FormBs.Group>
  );
}

export default Territories;
