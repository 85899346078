import type { ExpandColumnIcons } from "../Interfaces/Interfaces";
import type { ColumnDef } from "@tanstack/react-table";

export default function RtColumnExpander<T>(expandColumnIcons?: ExpandColumnIcons): ColumnDef<T> {
  return {
    id: "custom-expander",
    size: 1, // Used when using <div /> instead of <table />, usually with virtualised tables
    meta: {
      style: { textAlign: "center" },
      className: "rt__default-col-expand"
    },
    header: ({ table }) => {
      if (table.options.data.length === 0) {
        return null;
      }

      return (
        <div role="button" tabIndex={0} onClick={table.getToggleAllRowsExpandedHandler()} onKeyDown={table.getToggleAllRowsExpandedHandler()}>
          {table.getIsAllRowsExpanded() ? expandColumnIcons?.expandedIcon ?? "⬇️" : expandColumnIcons?.collapsedIcon ?? "➡️"}
        </div>
      );
    },
    cell: ({ row }) => {
      if (!row.getCanExpand()) {
        return null;
      }

      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      return (
        <div
          role="button"
          style={{ paddingLeft: `${row.depth * 2}rem` }}
          tabIndex={0}
          onClick={row.getToggleExpandedHandler()}
          onKeyDown={row.getToggleExpandedHandler()}
        >
          {row.getIsExpanded() ? expandColumnIcons?.expandedIcon ?? "⬇️" : expandColumnIcons?.collapsedIcon ?? "➡️"}
        </div>
      );
    }
  };
}
