import { useMemo } from "react";
import { Badge } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { BsPencilFill, BsXLg } from "react-icons/bs";
import { useError } from "@dekiru/react-error-boundary";
import { createColumnHelper } from "@tanstack/react-table";

import type { AssignmentRequest, AssignmentResponse, Book, Vendor } from "Src/api/Dto";
import type { SearchedBook } from "Src/components/Common/SearchBooks";

import RtTable from "Src/components/Common/Table/RtTable";

import useUser from "Src/hooks/useUser";

import { deactivateAssignment, updateAssignmentRequest } from "Src/squawk/ActionsAssignmentRequest";

import { removeDateClock, transformPricePropertiesResponse } from "../../../../../utils/Utils";
import { IsActiveCommon } from "../../Common/IsActiveCommon";

const columnHelper = createColumnHelper<Book>();

interface Props {
  books: Book[];
  assignments: AssignmentResponse[];
  updateBooks: () => void;
  vendor: Vendor;
  setSelectedBook: React.Dispatch<React.SetStateAction<SearchedBook | undefined>>;
}

export function List(props: Props) {
  const { assignments, books, updateBooks, vendor, setSelectedBook } = props;
  const { brId } = useUser();
  const dispatchError = useError();

  const dateExists = (isbn: string) => {
    const assignment = assignments.filter((a) => a.vendorBrId === vendor.vendorBrId && a.isbn === isbn);

    return assignment && assignment.length > 0;
  };

  const createAssignmentRequest = (assignment: AssignmentResponse) => {
    const assignmentRequest: AssignmentRequest = {
      isbn: assignment.isbn,
      validFrom: removeDateClock(assignment.validFrom),
      validTo: removeDateClock(assignment.validTo ?? ""),
      vendorBrId: assignment.vendorBrId,
      territoryAssignments: assignment.territoryAssignments,
      priceProperties: transformPricePropertiesResponse(vendor.priceProperties, assignment.priceProperties),
      distributionProperties: assignment.distributionProperties
    };

    return assignmentRequest;
  };

  const columns = useMemo(
    () => [
      columnHelper.display({
        header: "Aktiv",
        meta: {
          align: "center"
        },
        cell: (cell) => {
          const assignment = assignments.find((a) => a.vendorBrId === vendor.vendorBrId && a.isbn === cell.row.original.isbn);

          return assignment && <IsActiveCommon validTo={assignment.validTo ?? ""} />;
        }
      }),
      columnHelper.accessor("isbn", {
        header: "ISBN"
      }),
      columnHelper.accessor("title", {
        header: "Titel",
        meta: {
          style: {
            width: "40%"
          }
        }
      }),
      columnHelper.accessor("bookTypeName", {
        header: "Bandtyp",
        meta: {
          align: "center",
          filterComponentType: "select"
        },
        cell: (cell) => <Badge bg="secondary">{cell.getValue()}</Badge>
      }),
      columnHelper.accessor(
        (data) => {
          const assignment = assignments.find((a) => a.vendorBrId === vendor.vendorBrId && a.isbn === data.isbn);

          if (!assignment) {
            return;
          }

          return dateExists(data.isbn) && removeDateClock(assignment.validFrom);
        },
        {
          header: "Giltig från"
        }
      ),
      columnHelper.accessor(
        (data) => {
          const assignment = assignments.find((a) => a.vendorBrId === vendor.vendorBrId && a.isbn === data.isbn);
          if (!assignment) {
            return;
          }

          return dateExists(data.isbn) && removeDateClock(assignment.validTo ?? "");
        },
        {
          header: "Giltig till och med"
        }
      ),
      columnHelper.display({
        header: "Uppdatera tilldelning",
        meta: {
          align: "center"
        },
        cell: (cell) => {
          return (
            <Button
              size="sm"
              onClick={() => {
                const assignment = assignments.find((a) => a.vendorBrId === vendor.vendorBrId && a.isbn === cell.row.original.isbn);
                if (!assignment) {
                  return;
                }
                const { original: book } = cell.row;

                const assignmentRequest = createAssignmentRequest(assignment);

                updateAssignmentRequest(assignmentRequest);
                setSelectedBook?.({ isbn: book.isbn, title: book.title });
              }}
            >
              <BsPencilFill />
            </Button>
          );
        }
      }),
      columnHelper.display({
        header: "Inaktivera tilldelning",
        meta: {
          align: "center"
        },
        cell: (cell) => {
          return (
            <Button
              size="sm"
              variant="danger"
              onClick={() => {
                const assignment = assignments.find((a) => a.vendorBrId === vendor.vendorBrId && a.isbn === cell.row.original.isbn);
                if (!assignment) {
                  return;
                }

                const assignmentRequest = createAssignmentRequest(assignment);

                // Using catch() instead of try/catch because the latter requires an await which is unnecessary
                deactivateAssignment(brId, assignmentRequest).catch((error) => {
                  dispatchError(error);
                });
                updateBooks();
              }}
            >
              <BsXLg />
            </Button>
          );
        }
      })
    ],
    []
  );

  return (
    <RtTable
      bootstrapTableProps={{
        responsive: true,
        striped: true
      }}
      enablePagination={
        {
          // pageCount: 5
        }
      }
      useReactTableProps={{
        data: books,
        columns
      }}
    />
  );
}
