import Card from "react-bootstrap/Card";
import { Helmet } from "react-helmet";

import List from "./List";

export function Index() {
  return (
    <>
      <Helmet title="Böcker" />
      <Card className="p-4">
        <h2 className="pb-3">Böcker</h2>
        <List />
      </Card>
    </>
  );
}
