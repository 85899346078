import type { Table } from "@tanstack/react-table";

interface Props<T> {
  instance: Table<T>;
}

export default function RenderPagination<T>({ instance }: Props<T>) {
  return (
    <div style={{ display: "grid" }}>
      <div>
        <button disabled={!instance.getCanPreviousPage()} type="button" onClick={() => instance.setPageIndex(0)}>
          {"<<"}
        </button>
        <button disabled={!instance.getCanPreviousPage()} type="button" onClick={() => instance.previousPage()}>
          {"<"}
        </button>
        <button disabled={!instance.getCanNextPage()} type="button" onClick={() => instance.nextPage()}>
          {">"}
        </button>
        <button disabled={!instance.getCanNextPage()} type="button" onClick={() => instance.setPageIndex(instance.getPageCount() - 1)}>
          {">>"}
        </button>
        <span>
          <div>Page</div>
          <strong>
            {instance.getState().pagination.pageIndex + 1} of {instance.getPageCount()}
          </strong>
        </span>
        <span>
          | Go to page
          <input
            defaultValue={instance.getState().pagination.pageIndex + 1}
            type="number"
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              instance.setPageIndex(page);
            }}
          />
        </span>
        <select
          value={instance.getState().pagination.pageSize}
          onChange={(e) => {
            instance.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {`Show ${pageSize}`}
            </option>
          ))}
        </select>
      </div>
      <div>{`${instance.getRowModel().rows.length} rows`}</div>
    </div>
  );
}
