import { useState } from "react";
import { Alert, Button, InputGroup, Stack } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { BsSearch, BsXLg } from "react-icons/bs";

import type { Option } from "react-bootstrap-typeahead/types/types";
import type { Book } from "Src/api/Dto";

import { getBook } from "Src/api/Publishers";

import useUser from "Src/hooks/useUser";

import { updateAssignmentRequestProp } from "Src/squawk/ActionsAssignmentRequest";

export interface SearchedBook {
  isbn: string;
  title: string;
}

interface Props {
  searchedBook?: SearchedBook;
  onSelectBook?: (selectedBook: SearchedBook | undefined) => void;
}

interface State {
  loading: boolean;
  searchBookResults: Book[];
}

/**
 * @remark
 * react-bootstrap-typeahead has a type Option that created problems when trying to work with it.
 * So at places in the code where the type Option is needed we have been forced to use 'as' to
 * ensure typescript that the type we want to use actually exists.
 */
export function SearchBooks({ searchedBook, onSelectBook }: Props) {
  const [{ loading, searchBookResults }, setState] = useState<State>({
    loading: false,
    searchBookResults: []
  });

  const { brId } = useUser();

  const onSearch = async (query: string) => {
    setState((state) => ({ ...state, loading: true, searchBookResults: [] }));
    let results: Book[] = [];
    try {
      const book = await getBook(brId, query);
      results = [book];
    } catch {
      // getBook throws error if there's no search result.
      // Do nothing anyway, since no search result is handled internally by react-bootstrap-typeahead
    }

    setState((state) => ({ ...state, searchBookResults: results, loading: false }));
  };

  const onChange = (selected: Option[]) => {
    if (selected.length === 0) {
      return;
    }
    const [firstSelection] = selected;
    const { isbn, title } = firstSelection as Book;

    if (isbn === "0") {
      setState((state) => ({ ...state, searchBookResults: [] }));
      updateAssignmentRequestProp("", "isbn");
      onSelectBook?.({ isbn: "", title: "" });
    } else {
      setState((state) => ({ ...state, searchBookResults: [] }));
      updateAssignmentRequestProp(isbn, "isbn");
      onSelectBook?.({ isbn, title });
    }
  };

  const deleteIsbnButton = () => {
    onSelectBook?.(undefined);
    updateAssignmentRequestProp("", "isbn");
  };

  return (
    <Form.Group>
      <InputGroup>
        <InputGroup.Text>
          <BsSearch />
        </InputGroup.Text>
        <AsyncTypeahead
          emptyLabel="Kunde inte hitta en titel med detta isbn, vänligen sök igen"
          id="isbnTypeahead"
          inputProps={{ type: "number", max: 13 }}
          isLoading={loading}
          labelKey={(option) => {
            const correctOptionType = option as Book;

            return `${correctOptionType.isbn} - ${correctOptionType.title}`;
          }}
          minLength={13}
          options={searchBookResults}
          placeholder="Ange isbn"
          selected={[]}
          onChange={onChange}
          onSearch={onSearch}
        />
      </InputGroup>
      {searchedBook?.title && searchedBook?.title.length > 0 && (
        <Alert className="pt-1 pb-1 mt-3 pe-1" variant="secondary">
          <Stack direction="horizontal" gap={2}>
            <span
              style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
            >{`${searchedBook?.isbn} - ${searchedBook?.title}`}</span>
            <Button className="ms-auto" size="sm" variant="danger" onClick={deleteIsbnButton}>
              <BsXLg />
            </Button>
          </Stack>
        </Alert>
      )}
    </Form.Group>
  );
}
