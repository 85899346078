import { useMemo } from "react";
import { Form } from "react-bootstrap";

import type { Column, Table } from "@tanstack/react-table";

import DebouncedInput from "./DebouncedInput";

export default function RtTextFilter<T>({ column, instance }: { column: Column<T>; instance: Table<T> }) {
  const firstValue = instance.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);
  const columnFilterValue = column.getFilterValue();
  const columnFacetedUniqueValues = column.getFacetedUniqueValues();
  const filterComponentType = column.columnDef.meta?.filterComponentType ?? "input";

  const sortedUniqueValues = useMemo(
    () => (typeof firstValue === "number" ? [] : [...columnFacetedUniqueValues.keys()].sort()),
    [columnFacetedUniqueValues, firstValue]
  );

  return (
    <>
      {filterComponentType === "input" && (
        <DebouncedInput type="text" value={(columnFilterValue ?? "") as string} onChange={(value) => column.setFilterValue(value)} />
      )}
      {filterComponentType === "select" && (
        <Form.Select
          value={(columnFilterValue ?? "") as string}
          onChange={(event) => {
            column.setFilterValue(event.target.value);
          }}
        >
          <option value="">Välj filter</option>
          {sortedUniqueValues.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </Form.Select>
      )}
    </>
  );
}
