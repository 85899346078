import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  value: number | string;
  onChange: (value: number | string) => void;
  debounce?: number;
}

export default function DebouncedInput({ value: initialValue, onChange, debounce = 500, disabled, type, ...props }: Props) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce]);

  return (
    <Form.Control
      className="rt-table__global-filter"
      disabled={disabled}
      placeholder={props.placeholder}
      size="sm"
      type={type}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
    />
  );
}
