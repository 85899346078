import BokinfoLogoWhite from "Src/images/bokinfo_logo_vit.png";

interface Props {
  urlFacebook?: string;
  urlInstagram?: string;
  email?: string;
  phoneNumber?: string;
}

export default function BokinfoFooter({
  urlFacebook = "https://www.facebook.com/bokinfonorden/",
  urlInstagram = "https://www.instagram.com/bokinfo/",
  email = "info@bokinfo.se",
  phoneNumber = "08-698 33 30"
}: Props) {
  const urlBokinfoCookies = import.meta.env.BOKINFO_URL?.concat("/sv-se/cookie-list/");
  const urlBokinfoPolicies = import.meta.env.BOKINFO_URL?.concat("/sv-se/allt-pa-bokinfo/personuppgifter/");

  return (
    <footer className="footer mt-auto">
      <div className="container">
        <div className="row g-4">
          <div className="col-md-3 col-12">
            <div>
              <span className="category">Kontakt</span>
              <ul className="list-unstyled">
                <li>
                  <i className="bi bi-envelope me-2" />
                  <a href={`mailto:${email}`}>{email}</a>
                </li>
                <li>
                  <i className="bi bi-phone me-2" />
                  <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-12">
            <div>
              <span className="category">Om</span>
              <ul className="list-unstyled">
                <li>
                  <a href={urlBokinfoCookies} rel="noreferrer" target="_blank">
                    Cookies
                  </a>
                </li>
                <li>
                  <a href={urlBokinfoPolicies} rel="noreferrer" target="_blank">
                    Hantering av personuppgifter
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-12">
            <div className="col-megamenu">
              {/* <span className="category">Hjälp och manualer</span>
              <ul className="list-unstyled">
                <li>
                  <a href="#">Lägg till en ny bok</a>
                </li>
                <li>
                  <a href="#">Ta emot beställningar</a>
                </li>
                <li>
                  <a href="#">Hitta och beställ till din bokhandel</a>
                </li>
                <li>
                  <a href="#">Standarder och filformat</a>
                </li>
                <li>
                  <a href="#">Alla hjälpsidor</a>
                </li>
              </ul> */}
            </div>
          </div>
          <div className="col-md-3 col-12">
            <div>
              <span className="category">Följ oss</span>
              <ul className="list-unstyled">
                <li>
                  <i className="bi bi-facebook me-2" />
                  <a href={urlFacebook} rel="noreferrer" target="_blank">
                    Facebook
                  </a>
                </li>
                <li>
                  <i className="bi bi-instagram me-2" />
                  <a href={urlInstagram} rel="noreferrer" target="_blank">
                    Instagram
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="footer__detail">
              <img alt="logo" aria-hidden="true" className="img-fluid me-auto" src={BokinfoLogoWhite} />
              <span className="footer__detail__copy-right">© {new Date().getFullYear()} Bokinfo</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
