import { useEffect, useMemo, useState } from "react";
import { Badge } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { BsFiles } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { useError } from "@dekiru/react-error-boundary";
import { createColumnHelper } from "@tanstack/table-core";

import type { Book } from "Src/api/Dto";

import { getBooks } from "Src/api/Publishers";

import RtTable from "Src/components/Common/Table/RtTable";

import useUser from "Src/hooks/useUser";

import { removeDateClock } from "Src/utils/Utils";

const columnHelper = createColumnHelper<Book>();

interface State {
  loading: boolean;
  books: Book[];
}

export default function List() {
  const [{ loading, books }, setState] = useState<State>({
    loading: true,
    books: []
  });

  const user = useUser();
  const history = useHistory();
  const dispatchError = useError();

  useEffect(() => {
    start();
  }, []);

  const start = async () => {
    try {
      setState((state) => ({ ...state, loading: true }));

      const books = await getBooks(user.brId);

      setState((state) => ({ ...state, books, loading: false }));
    } catch (error) {
      dispatchError(error);
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("isbn", {
        header: "ISBN"
      }),
      columnHelper.accessor("title", {
        header: "Titel",
        meta: {
          style: {
            width: "40%"
          }
        }
      }),
      columnHelper.accessor("bookTypeName", {
        header: "Bandtyp",
        meta: {
          align: "center",
          filterComponentType: "select",
          style: {
            width: "135px"
          }
        },
        cell: (cell) => <Badge bg="secondary">{cell.getValue()}</Badge>
      }),
      columnHelper.accessor((data) => removeDateClock(data.releaseDate ?? ""), {
        header: "Utgivningsdatum"
      }),
      columnHelper.display({
        header: "Tilldela bok till återförsäljare",
        meta: {
          align: "center"
        },
        cell: (cell) => (
          <Button
            size="sm"
            onClick={() => history.push(`/publisher/books/book-list/assignment/${cell.row.original.isbn}`, { book: cell.row.original })}
          >
            Återförsäljare
          </Button>
        )
      }),
      columnHelper.display({
        header: "Hantera filer",
        meta: {
          align: "center"
        },
        cell: (cell) => (
          <Button size="sm" onClick={() => history.push(`/publisher/books/book-list/upload/${cell.row.original.isbn}`, { book: cell.row.original })}>
            <BsFiles />
          </Button>
        )
      })
    ],
    []
  );

  return (
    <RtTable
      bootstrapTableProps={{
        responsive: true,
        striped: true
      }}
      enablePagination={
        {
          // pageCount: 5
        }
      }
      loading={loading}
      useReactTableProps={{
        data: books,
        columns
      }}
    />
  );
}
