import { createContext } from "react";

import type { User } from "Src/api/Dto";
import type { UserType } from "Src/api/Enums";

interface UserContextState extends User {
  unauthorized: boolean;
  epiKey: string;
  apiKey: string;
}

const defaultUserContextState: UserContextState = {
  brId: "",
  id: 0,
  token: "",
  name: "",
  type: {} as UserType,
  subscriptions: [],
  unauthorized: true,
  epiKey: "",
  apiKey: ""
};

const UserContext = createContext<UserContextState>(defaultUserContextState);

export type { UserContextState };

export { UserContext, defaultUserContextState };
