import { useState } from "react";
import { Col, InputGroup, Row } from "react-bootstrap";
import FormBs from "react-bootstrap/Form";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { BsSearch } from "react-icons/bs";

import type { Option } from "react-bootstrap-typeahead/types/types";
import type { Publisher } from "Src/api/Dto";

interface Props {
  publishers: Publisher[];
  onChange: (key: string) => void;
}

interface State {
  searchPublisherResults: Publisher[];
  loading: boolean;
}

export function PublisherDropDown(props: Props) {
  const { publishers, onChange } = props;

  const [{ searchPublisherResults, loading }, setState] = useState<State>({
    searchPublisherResults: [],
    loading: false
  });

  const onSearchTypeahead = (query: string) => {
    setState((state) => ({ ...state, loading: true }));
    const results: Publisher[] = publishers.filter((p) => p.name.toLowerCase().indexOf(query.toLocaleLowerCase()) !== -1);
    setState((state) => ({ ...state, searchPublisherResults: results, loading: false }));
  };

  const onChangeTypeahead = (selected: Option[]) => {
    if (selected.length > 0) {
      const [firstSelection] = selected;
      const { publisherBrId } = firstSelection as Publisher;

      setState((state) => ({ ...state, searchPublisherResults: [] }));
      onChange(publisherBrId);
    }
  };

  return (
    <FormBs>
      <FormBs.Label>
        <strong>Förlag</strong>
      </FormBs.Label>
      <Row>
        <Col md={4}>
          <InputGroup>
            <InputGroup.Text>
              <BsSearch />
            </InputGroup.Text>
            <AsyncTypeahead
              id="publisherSearchTypeahead"
              isLoading={loading}
              labelKey="name"
              minLength={1}
              options={searchPublisherResults}
              placeholder="Ange förlagsnamn"
              onChange={onChangeTypeahead}
              onSearch={onSearchTypeahead}
            />
          </InputGroup>
        </Col>
      </Row>
    </FormBs>
  );
}
