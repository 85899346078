import { useState } from "react";
import { NavDropdown, Stack } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import useMediaQuery from "Src/hooks/useMediaQuery";
import useUser from "Src/hooks/useUser";

import BokinfoLogoBlack from "Src/images/bokinfo_logo_svart.png";

export interface MenuItem {
  name?: string;
  url?: string;
  subItems?: MenuItem[];
}

interface Props {
  menuItems?: MenuItem[];
}

export default function BokinfoHeaderMenu({ menuItems }: Props) {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const user = useUser();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const toggleShowMobileMenu = () => setShowMobileMenu((prevState) => !prevState);
  const closeMobileMenu = () => setShowMobileMenu(false);

  return (
    <header className="header">
      <div id="app-nav">
        <nav className="navbar--thin navbar navbar-expand-lg navbar-light">
          <div className="flex-wrap align-items-end container">
            <div className="w-100 col-md-12">
              <Stack direction="vertical" gap={isMobile ? 3 : 0}>
                <div className="text-end" style={{ fontSize: "12px" }}>
                  Du är inloggad som {user.name}
                </div>
                <div>
                  <button
                    aria-controls="basic-navbar-nav"
                    aria-label="Toggle navigation"
                    className={`navbar-toggler ${showMobileMenu ? "" : "collapsed"}`}
                    type="button"
                    onClick={toggleShowMobileMenu}
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <a className="ms-3 ms-lg-0 d-inline-block d-lg-none navbar-brand" href={import.meta.env.BOKINFO_URL} onClick={closeMobileMenu}>
                    <img alt="logo" className="img-fluid" src={BokinfoLogoBlack} />
                  </a>
                  {/* <div className="position-relative d-inline-block float-end mb-1">
                <span className="mobile-icon-item ms-auto d-block d-lg-none navbar-brand">
                  <i className="bi bi-person me-2" />
                </span>
              </div>*/}
                </div>
                <div className={`navbar-collapse collapse ${showMobileMenu ? "show" : ""}`} id="basic-navbar-nav">
                  <a className="ms-3 ms-lg-0 d-none d-lg-inline-block navbar-brand" href={import.meta.env.BOKINFO_URL} onClick={closeMobileMenu}>
                    <img alt="logo" className="img-fluid" src={BokinfoLogoBlack} />
                  </a>
                  <div className="me-auto menu flex-wrap navbar-nav">
                    {menuItems?.map((menuItem) => {
                      if (menuItem.subItems) {
                        return (
                          <NavDropdown key={menuItem.name} id="basic-nav-dropdown" title={menuItem.name}>
                            {menuItem.subItems.map((subItem) => {
                              return (
                                <NavDropdown.Item
                                  key={subItem.url}
                                  as={NavLink}
                                  className="text-wrap"
                                  to={subItem.url ?? ""}
                                  onClick={closeMobileMenu}
                                >
                                  {subItem.name}
                                </NavDropdown.Item>
                              );
                            })}
                          </NavDropdown>
                        );
                      }

                      return (
                        <NavLink key={menuItem.url} className="menu__item nav-link" to={menuItem.url ?? ""} onClick={closeMobileMenu}>
                          {menuItem.name}
                        </NavLink>
                      );
                    })}
                  </div>
                </div>
              </Stack>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
