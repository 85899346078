import { Col, Container, Row } from "react-bootstrap";
import FormBs from "react-bootstrap/Form";

import type { SearchedBook } from "../../../../Common/SearchBooks";
import type { Book, Vendor } from "Src/api/Dto";

import { useSquawkAssignmentRequest } from "Src/squawk/StoreAssignmentRequest";

import { SearchBooks } from "../../../../Common/SearchBooks";
import FormCommon from "../../Common/FormCommon";

interface Props {
  vendor: Vendor;
  books: Book[];
  selectedBook?: SearchedBook;
  onSelectBook?: (selectedBook: SearchedBook | undefined) => void;
}

export function Form(props: Props) {
  const { vendor, books, selectedBook, onSelectBook } = props;

  const { isbn } = useSquawkAssignmentRequest();

  const editMode = books?.some((book) => book.isbn === isbn) ?? false;

  return (
    <Container className="ms-0">
      <Row>
        <Col lg="4" md="6">
          <FormBs>
            <FormBs.Group className="mb-3">
              <FormBs.Label>
                <strong>ISBN</strong>
              </FormBs.Label>
              {editMode ? (
                <FormBs.Control disabled placeholder={`${selectedBook?.isbn} - ${selectedBook?.title}`} />
              ) : (
                <SearchBooks searchedBook={selectedBook} onSelectBook={onSelectBook} />
              )}
            </FormBs.Group>
            <FormCommon editMode={editMode} keepVendorBrIdOrIsbnOnCancel="vendorBrId" vendor={vendor} onSelectBook={onSelectBook} />
          </FormBs>
        </Col>
      </Row>
    </Container>
  );
}
