// @ts-nocheck
type IndexedProperty = string | string[];
type IndexedObject = { [key: string]: IndexedProperty };
type QueryLookup = {
  get: (name: string) => string | string[];
  names: () => string[];
  exists: (name: string) => boolean;
};
type ParseQuery = (queryString: string) => QueryLookup;
type ParsedSegment = { key: string; value: string; isArray?: boolean };
type SplitSeg = string[];
type SegmentParser = (segs: SplitSeg) => ParsedSegment;

const parseSegment: SegmentParser = (segs: SplitSeg): ParsedSegment => {
  const [key, value] = segs;
  if (key.match(/\[\]$/)) {
    return {
      key: key.substr(0, key.length - 2).toLowerCase(),
      isArray: true,
      value: decodeURI(value)
    };
  }
  return {
    key: key.toLowerCase(),
    value: decodeURI(value)
  };
};

export const parseQuery: ParseQuery = (queryString: string): QueryLookup => {
  if (queryString.charAt(0) === "?") {
    queryString = queryString.substr(1);
  }

  const parsedObject: IndexedObject = queryString
    .split("&")
    .map((seg) => seg.split("="))
    .reduce((acc, cur) => {
      const segment = parseSegment(cur);

      if (acc[segment.key] === undefined) {
        acc[segment.key] = segment.isArray ? [segment.value] : segment.value;
        return acc;
      }

      if (Array.isArray(acc[segment.key])) {
        acc[segment.key].push(segment.value);
      } else {
        acc[segment.key] = [acc[segment.key], segment.value];
      }

      return acc;
    }, {});

  return {
    get: (name: string) => parsedObject[name.toLowerCase()] || "",
    names: () => Object.getOwnPropertyNames(parsedObject),
    exists: (name: string) => !!parsedObject[name.toLowerCase()]
  };
};
