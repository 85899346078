import Button from "react-bootstrap/Button";
import { useHistory } from "react-router";

import type { Agreement, SignedAgreementResponse, Vendor } from "Src/api/Dto";

import { removeDateClock } from "Src/utils/Utils";

interface Props {
  signedAgreement: SignedAgreementResponse | undefined;
  vendorAgreements: Agreement[];
  vendor: Vendor;
  mode: "create" | "edit";
}

export function CreateEditLink({ mode, vendor, signedAgreement, vendorAgreements }: Props) {
  const history = useHistory();
  if (mode === "create") {
    if (signedAgreement) {
      const agreement = vendorAgreements.find((a) => a.agreementType === signedAgreement.agreementType);

      return (
        <>
          {agreement && agreement.name} - {removeDateClock(signedAgreement.signedDate)}
        </>
      );
    }

    return (
      <Button
        size="sm"
        onClick={() =>
          history.push("/publisher/vendors/agreement", {
            mode,
            vendor,
            signedAgreement,
            vendorAgreements
          })
        }
      >
        Välj avtal
      </Button>
    );
  }
  if (mode === "edit") {
    if (signedAgreement && vendor.vendorSettings.length !== 0) {
      return (
        <Button
          size="sm"
          variant="secondary"
          onClick={() =>
            history.push("/publisher/vendors/agreement", {
              mode,
              vendor,
              signedAgreement,
              vendorAgreements
            })
          }
        >
          Visa egenskaper
        </Button>
      );
    }

    return <>-</>;
  }

  return null;
}
