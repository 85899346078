import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useError } from "@dekiru/react-error-boundary";

import type { UserContextState } from "./UserContext";
import type { User } from "Src/api/Dto";

import { UserType } from "Src/api/Enums";
import HttpClient from "Src/api/HttpClient";
import { auth } from "Src/api/WebApp";

import { appInsights } from "Src/Root";
import { loadAll } from "Src/squawk/ActionsApp";

import { parseQuery } from "../../utils/QueryParser";

import { defaultUserContextState, UserContext } from "./UserContext";

interface Props {
  children?: React.ReactNode;
}

export function UserProvider({ children }: Props) {
  const [state, setState] = useState<UserContextState>(defaultUserContextState);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const dispatchError = useError();
  const history = useHistory();

  const { unauthorized } = state;

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    // TODO: Add check of path so we only parse Key if we're at the root url
    const queryString = parseQuery(location.search);

    const epiKey = queryString.get("Key") as string;
    const epiKeyStorage = localStorage.getItem("epiKey");
    const userStringified = localStorage.getItem("user");
    let user: User;
    let redirect = false;
    setLoading(true);
    try {
      if (epiKey === "" && userStringified && epiKeyStorage && epiKeyStorage !== "null" && userStringified !== "null") {
        // Handles case when an authenticated user has reloaded the page by getting and setting data from session storage
        user = JSON.parse(userStringified) as User;
        redirect = location.pathname === "/";
      } else {
        user = await auth(epiKey);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("epiKey", epiKey);
        redirect = location.pathname === "/";
      }
      user = { ...user, brId: user.brId.trim() };
      setState({ ...state, ...user, unauthorized: false, epiKey, apiKey: user.subscriptions[0]?.value });
      HttpClient.setDefaultHeaders({ "Ocp-Apim-Subscription-Key": user.subscriptions[0]?.value });

      // In test and production we use api management. Api management takes care of adding brId as a header
      if (import.meta.env.NODE_ENV === "development") {
        HttpClient.setDefaultHeaders({ brId: user.brId });
      }

      if (user.type === UserType.Publisher) {
        await loadAll(user.brId);
      }

      appInsights.addTelemetryInitializer((envelope) => {
        const telemetryItem = envelope.baseData;
        if (telemetryItem) {
          telemetryItem.properties["ai.user.brId"] = user.brId;
          telemetryItem.properties["ai.user.type"] = UserType[user.type];
        }

        return true;
      });

      setLoading(false);
      if (redirect) {
        if (user.type === UserType.Admin) {
          history.replace("/admin/publisherbooks");
        } else {
          const isbn = queryString.get("isbn") as string;
          history.replace(isbn ? `/publisher/upload/${isbn}` : "/publisher/books/book-list");
        }
      }
    } catch (error) {
      dispatchError(error);
    }
  };

  if (loading) {
    return (
      <div className="h-100 d-flex justify-content-center align-items-center">
        <Spinner animation="border" />
      </div>
    );
  } else if (unauthorized) {
    return <>Ej autentiserad</>;
  }

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
}
