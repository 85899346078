import { useEffect, useState } from "react";
import { Row, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { useError } from "@dekiru/react-error-boundary";

import type { AssignmentResponse, Vendor } from "Src/api/Dto";

import { getPublisherBookAssignments, getPublisherBookVendors } from "Src/api/Admin";

import useToggle from "Src/hooks/useToggle";

import { removeDateClock } from "Src/utils/Utils";

interface Props {
  brId: string;
  isbn: string;
}

interface State {
  loading: boolean;
  vendors: Vendor[];
  assigments: AssignmentResponse[];
}

export function BookVendors({ brId, isbn }: Props) {
  const [{ loading, vendors, assigments }, setState] = useState<State>({
    loading: true,
    vendors: [],
    assigments: []
  });

  const [modalOpen, toggleModalOpen] = useToggle();

  const dispatchError = useError();

  useEffect(() => {
    if (!modalOpen) {
      return;
    }
    getBookVendors();
  }, [modalOpen]);

  const getBookVendors = async () => {
    try {
      const [vendors, assigments] = await Promise.all([getPublisherBookVendors(brId, isbn), getPublisherBookAssignments(brId, isbn)]);
      setState((state) => ({ ...state, vendors, assigments, loading: false }));
    } catch (error) {
      dispatchError(error);
    }
  };

  return (
    <>
      <Button onClick={toggleModalOpen}>Återförsäljare</Button>
      <Modal show={modalOpen} size="lg">
        <Modal.Header>
          <Modal.Title>Återförsäljare för {isbn}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Row className="justify-content-center">
              <Spinner animation="border" />
            </Row>
          ) : (
            <Table responsive striped>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Namn</th>
                  <th>Giltig från</th>
                  <th>Giltig till och med</th>
                </tr>
              </thead>
              <tbody>
                {vendors.map((vendor) => (
                  <tr key={vendor.vendorBrId}>
                    <td>{vendor.vendorBrId}</td>
                    <td>{vendor.name}</td>
                    <td>{removeDateClock(assigments.filter((a) => a.vendorBrId === vendor.vendorBrId)[0].validFrom)}</td>
                    <td>{removeDateClock(assigments.filter((a) => a.vendorBrId === vendor.vendorBrId)[0].validTo ?? "")}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModalOpen}>
            Stäng
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
