import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { BsDownload, BsXLg } from "react-icons/bs";

import type { Book, ContentType } from "Src/api/Dto";

interface Props {
  book: Book;
  uploadedContentTypes: ContentType[];
  deleteContent: (contentTypeId: number) => void;
  downloadBook: (book: Book, contentTypeId: number) => void;
}

export function UploadedList(props: Props) {
  const { book, uploadedContentTypes, downloadBook, deleteContent } = props;

  if (uploadedContentTypes.length <= 0) {
    return null;
  }

  return (
    <Card className="p-4 mt-3">
      <h2>Uppladdade filer</h2>
      <Table responsive striped>
        <thead>
          <tr>
            <th />
            <th className="text-center">Ladda ner</th>
            <th className="text-center">Radera</th>
          </tr>
        </thead>
        <tbody>
          {uploadedContentTypes.map((c) => (
            <tr key={c.contentTypeId}>
              <td>{c.name}</td>
              <td className="text-center">
                <Button size="sm" onClick={() => downloadBook(book, c.contentTypeId)}>
                  <BsDownload color="white" />
                </Button>
              </td>
              <td className="text-center">
                <Button size="sm" variant="danger" onClick={() => deleteContent(c.contentTypeId)}>
                  <BsXLg />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
}
