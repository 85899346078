import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsCircleFill } from "react-icons/bs";
import dayjs from "dayjs";

interface Props {
  validTo: string;
}

export function IsActiveCommon({ validTo }: Props) {
  const currDate = dayjs();
  const toDate = dayjs(validTo);
  let color: "green" | "red" | "yellow" | undefined;
  let titleText = "";
  if (toDate.isBefore(currDate, "days") || !toDate.isValid()) {
    color = "red";
    titleText = "Tilldelningen är inaktiv";
  } else if (toDate.isSame(currDate, "days")) {
    color = "yellow";
    titleText = "Tilldelningen kommer bli inaktiv efter dagens datum";
  } else {
    color = "green";
    titleText = "Tilldelningen är aktiv";
  }

  return (
    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{titleText}</Tooltip>} placement="right">
      <span>
        <BsCircleFill color={color} />
      </span>
    </OverlayTrigger>
  );
}
