import dayjs from "dayjs";

import type { AssignmentRequest, PricePropertyItemRequest, TerritoryAssignment } from "Src/api/Dto";

import { createAssignment, updateAssignment } from "Src/api/Publishers";

import { actionAssignmentRequest, pendingAssignmentRequest } from "./StoreAssignmentRequest";

export const updateAssignmentRequest = actionAssignmentRequest(
  (assignmentRequest, assignmentRequestProps: Partial<AssignmentRequest> | undefined) => {
    if (!assignmentRequestProps) {
      return;
    }

    return { ...assignmentRequest, ...assignmentRequestProps };
  }
);

export const updateAssignmentRequestProp = actionAssignmentRequest((assignmentRequest, value: string[] | string, name: keyof AssignmentRequest) => {
  if (name === "territoryAssignments" && typeof value === "object") {
    const territoryAssignments: TerritoryAssignment[] = value.map((v) => ({ excluded: false, territoryCode: v }));

    return {
      ...assignmentRequest,
      territoryAssignments
    };
  }

  // Make sure validTo date is always after assigned validFrom date.
  if (name === "validFrom" && typeof value === "string" && assignmentRequest.validTo !== null && assignmentRequest.validTo !== "") {
    const validFromDate = new Date(value);
    const validToDate = new Date(assignmentRequest.validTo);
    // const validToSelected = validFromDate > validToDate ? validFromDate : validToDate;
    let validToSelected = new Date();
    if (validFromDate > validToDate) {
      validFromDate.setDate(validFromDate.getDate() + 1);
      validToSelected = validFromDate;
    } else {
      validToSelected = validToDate;
    }

    return {
      ...assignmentRequest,
      validFrom: value,
      validTo: validToSelected.toLocaleDateString("sv-SE")
    };
  }

  return {
    ...assignmentRequest,
    [name]: value
  };
});

export const createUpdateAssignment = actionAssignmentRequest(async (assignmentRequest, brId: string, editMode: boolean) => {
  try {
    pendingAssignmentRequest("vendorBrId", true);

    if (editMode) {
      await updateAssignment(brId, assignmentRequest);
    } else {
      await createAssignment(brId, assignmentRequest);
    }

    return {
      isbn: "",
      priceProperties: [],
      territoryAssignments: [],
      validFrom: "",
      validTo: "",
      vendorBrId: ""
    };
  } finally {
    pendingAssignmentRequest("vendorBrId", false);
  }
});

export const deactivateAssignment = actionAssignmentRequest(async ({ isbn }, brId: string, assignment: AssignmentRequest | undefined) => {
  pendingAssignmentRequest("vendorBrId", true);

  if (!assignment) {
    // TODO: Throw error
    return;
  }
  assignment.validTo = dayjs().format("YYYY-MM-DD");
  await updateAssignment(brId, assignment);

  pendingAssignmentRequest("vendorBrId", false);

  return {
    isbn,
    priceProperties: [],
    territoryAssignments: [],
    validFrom: "",
    validTo: "",
    vendorBrId: ""
  };
});

export const updatePricePropertyItems = actionAssignmentRequest(
  (assignmentRequest, pricePropertyName: string, pricePropertyItems: PricePropertyItemRequest[]) => {
    const { priceProperties: pricePropertiesStore } = assignmentRequest;

    const priceProperties = pricePropertiesStore?.map((priceProperty) => {
      if (priceProperty.name === pricePropertyName) {
        return {
          name: pricePropertyName,
          pricePropertyItems
        };
      }

      return priceProperty;
    });

    return { ...assignmentRequest, priceProperties };
  }
);

export const clearAssignmentRequest = actionAssignmentRequest(() => {
  return {
    isbn: "",
    priceProperties: [],
    territoryAssignments: [],
    validFrom: "",
    validTo: "",
    vendorBrId: ""
  };
});
