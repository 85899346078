import createStore from "squawk-react";

import type { AssignmentRequest } from "Src/api/Dto";

export const {
  action: actionAssignmentRequest,
  useSquawk: useSquawkAssignmentRequest,
  pending: pendingAssignmentRequest,
  usePending: usePendingAssignmentRequest
} = createStore<AssignmentRequest>(
  {
    isbn: "",
    priceProperties: [],
    territoryAssignments: [],
    validFrom: "",
    validTo: "",
    vendorBrId: "",
    distributionProperties: []
  },
  true
);
