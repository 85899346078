import { flexRender } from "@tanstack/react-table";

import type { SortColumnIcons } from "../Interfaces/Interfaces";
import type { Header } from "@tanstack/react-table";

export interface RTHeaderCellProps<T> {
  header: Header<T, unknown>;
  filterComponent?: JSX.Element;
  sortColumnIcons?: SortColumnIcons;
}

export default function RtHeaderCell<T>({ header, filterComponent, sortColumnIcons }: RTHeaderCellProps<T>) {
  if (header.isPlaceholder) {
    return null;
  }

  const isColumnSorted = header.column.getIsSorted();
  const canColumnSort = header.column.getCanSort();

  if (canColumnSort) {
    return (
      <div
        className="rt__default-header-cell"
        role="button"
        tabIndex={0}
        onClick={header.column.getToggleSortingHandler()}
        onKeyDown={header.column.getToggleSortingHandler()}
      >
        {flexRender(header.column.columnDef.header, header.getContext())}
        <span>
          {!isColumnSorted && (sortColumnIcons?.sortedIconDefault ?? "↕️")}
          {isColumnSorted === "asc" && (sortColumnIcons?.sortedIconAsc ?? "⬆️")}
          {isColumnSorted === "desc" && (sortColumnIcons?.sortedIconDesc ?? "⬇️")}
        </span>
        {filterComponent && header.column.getCanFilter() && <div>{filterComponent}</div>}
      </div>
    );
  }

  return (
    <>
      {flexRender(header.column.columnDef.header, header.getContext())}
      {filterComponent && header.column.getCanFilter() && <div>{filterComponent}</div>}
    </>
  );
}
