import { useState } from "react";

/**
 * Hook för att växla mellan true och false.
 *
 * @param state - specifiera start-värdet. default är false
 */
export default function useToggle(state = false) {
  const [value, setValue] = useState(state);
  const toggle = () => setValue(!value);
  const set = (val: boolean) => setValue(val);

  return [value, toggle, set] as const;
}
