/* eslint-disable */
/* eslint-disable prettier/prettier */
// DO NOT EDIT, THIS CODE IS TOOL GENERATED
import _client from "./HttpClient";
import * as Enums from "./Enums";
import * as DTO from "./Dto";
export const commitUploadSession = async (publisherBrId: string, sessionId: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<any> => {
    return _client.httpFetch<any>('PUT', _client.resolveUrl(`/Publishers/${publisherBrId}/CommitUploadSession?sessionId=${encodeURIComponent((sessionId != null ? sessionId : ""))}`), null, $headers, uploadEvent, returnXhr);
};
export const createAssignment = async (publisherBrId: string, assignment: DTO.AssignmentRequest, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.AssignmentRequest> => {
    return _client.httpFetch<DTO.AssignmentRequest>('POST', _client.resolveUrl(`/Publishers/${publisherBrId}/Assignments`), assignment, $headers, uploadEvent, returnXhr);
};
export const createSignedAgreement = async (publisherBrId: string, signedAgreementRequest: DTO.SignedAgreementRequest, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.SignedAgreementResponse> => {
    return _client.httpFetch<DTO.SignedAgreementResponse>('POST', _client.resolveUrl(`/Publishers/${publisherBrId}/SignedAgreements`), signedAgreementRequest, $headers, uploadEvent, returnXhr);
};
export const createUploadSessionAsync = async (publisherBrId: string, isbn: string, contentTypeId: number, fileSize: number, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.UploadSessionResponse> => {
    return _client.httpFetch<DTO.UploadSessionResponse>('POST', _client.resolveUrl(`/Publishers/${publisherBrId}/Books/${isbn}/Contents/${contentTypeId}/CreateUploadSession?fileSize=${(fileSize != null ? fileSize : "")}`), null, $headers, uploadEvent, returnXhr);
};
export const deleteBook = async (publisherBrId: string, isbn: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<any> => {
    return _client.httpFetch<any>('DELETE', _client.resolveUrl(`/Publishers/${publisherBrId}/Books/${isbn}`), null, $headers, uploadEvent, returnXhr);
};
export const deleteContent = async (publisherBrId: string, isbn: string, contentTypeId: number, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<any> => {
    return _client.httpFetch<any>('DELETE', _client.resolveUrl(`/Publishers/${publisherBrId}/Books/${isbn}/Contents/${contentTypeId}`), null, $headers, uploadEvent, returnXhr);
};
export const getAgreements = async (publisherBrId: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Agreement[]> => {
    return _client.httpFetch<DTO.Agreement[]>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/Agreements`), null, $headers, uploadEvent, returnXhr);
};
export const getAssignments = async (publisherBrId: string, updatedSince: string | null, activatedIntervalFromDate: string | null, activatedIntervalToDate: string | null, deactivatedIntervalFromDate: string | null, deactivatedIntervalToDate: string | null, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.AssignmentResponse[]> => {
    return _client.httpFetch<DTO.AssignmentResponse[]>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/Assignments?updatedSince=${(updatedSince != null ? updatedSince : "")}&activatedIntervalFromDate=${(activatedIntervalFromDate != null ? activatedIntervalFromDate : "")}&activatedIntervalToDate=${(activatedIntervalToDate != null ? activatedIntervalToDate : "")}&deactivatedIntervalFromDate=${(deactivatedIntervalFromDate != null ? deactivatedIntervalFromDate : "")}&deactivatedIntervalToDate=${(deactivatedIntervalToDate != null ? deactivatedIntervalToDate : "")}`), null, $headers, uploadEvent, returnXhr);
};
export const getBook = async (publisherBrId: string, isbn: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Book> => {
    return _client.httpFetch<DTO.Book>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/Books/${isbn}`), null, $headers, uploadEvent, returnXhr);
};
export const getBooks = async (publisherBrId: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Book[]> => {
    return _client.httpFetch<DTO.Book[]>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/Books`), null, $headers, uploadEvent, returnXhr);
};
export const getBooksCount = async (publisherBrId: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<number> => {
    return _client.httpFetch<number>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/Books/Count`), null, $headers, uploadEvent, returnXhr);
};
export const getBooksInterval = async (publisherBrId: string, pageNumber: number, pageSize: number, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Book[]> => {
    return _client.httpFetch<DTO.Book[]>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/Books/${pageNumber}/${pageSize}`), null, $headers, uploadEvent, returnXhr);
};
export const getBookTypes = async (publisherBrId: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.BookType[]> => {
    return _client.httpFetch<DTO.BookType[]>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/BookTypes`), null, $headers, uploadEvent, returnXhr);
};
export const getContent = async (publisherBrId: string, isbn: string, contentTypeId: number, range: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<void> => {
    return _client.httpFetch<void>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/Books/${isbn}/Contents/${contentTypeId}`), range, $headers, uploadEvent, returnXhr);
};
export const getContents = async (publisherBrId: string, isbn: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.ContentType[]> => {
    return _client.httpFetch<DTO.ContentType[]>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/Books/${isbn}/Contents`), null, $headers, uploadEvent, returnXhr);
};
export const getContentTypes = async (publisherBrId: string, bookTypeId: number, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.ContentType[]> => {
    return _client.httpFetch<DTO.ContentType[]>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/BookTypes/${bookTypeId}/ContentTypes`), null, $headers, uploadEvent, returnXhr);
};
export const getPublisherAgreements = async (publisherBrId: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Agreement[]> => {
    return _client.httpFetch<DTO.Agreement[]>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/PublisherAgreements`), null, $headers, uploadEvent, returnXhr);
};
export const getPublisherBookAssignments = async (publisherBrId: string, isbn: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.AssignmentResponse[]> => {
    return _client.httpFetch<DTO.AssignmentResponse[]>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/Books/${isbn}/Assignments`), null, $headers, uploadEvent, returnXhr);
};
export const getPublisherBookVendors = async (publisherBrId: string, isbn: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Vendor[]> => {
    return _client.httpFetch<DTO.Vendor[]>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/Books/${isbn}/Vendors`), null, $headers, uploadEvent, returnXhr);
};
export const getSignedAgreements = async (publisherBrId: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.SignedAgreementResponse[]> => {
    return _client.httpFetch<DTO.SignedAgreementResponse[]>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/SignedAgreements`), null, $headers, uploadEvent, returnXhr);
};
export const getTerritories = async (publisherBrId: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Territory[]> => {
    return _client.httpFetch<DTO.Territory[]>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/Territories`), null, $headers, uploadEvent, returnXhr);
};
export const getUploadSession = async (publisherBrId: string, sessionId: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.UploadSessionResponse> => {
    return _client.httpFetch<DTO.UploadSessionResponse>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/GetUploadSession?sessionId=${encodeURIComponent((sessionId != null ? sessionId : ""))}`), null, $headers, uploadEvent, returnXhr);
};
export const getVendor = async (publisherBrId: string, vendorBrId: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Vendor> => {
    return _client.httpFetch<DTO.Vendor>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/Vendors/${vendorBrId}`), null, $headers, uploadEvent, returnXhr);
};
export const getVendorBooks = async (publisherBrId: string, vendorBrId: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Book[]> => {
    return _client.httpFetch<DTO.Book[]>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/Vendors/${vendorBrId}/Books`), null, $headers, uploadEvent, returnXhr);
};
export const getVendorBooksCount = async (publisherBrId: string, vendorBrId: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<number> => {
    return _client.httpFetch<number>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/Vendors/${vendorBrId}/Books/Count`), null, $headers, uploadEvent, returnXhr);
};
export const getVendorBooksInterval = async (publisherBrId: string, vendorBrId: string, pageNumber: number, pageSize: number, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Book[]> => {
    return _client.httpFetch<DTO.Book[]>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/Vendors/${vendorBrId}/Books/${pageNumber}/${pageSize}`), null, $headers, uploadEvent, returnXhr);
};
export const getVendors = async (publisherBrId: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Vendor[]> => {
    return _client.httpFetch<DTO.Vendor[]>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/Vendors`), null, $headers, uploadEvent, returnXhr);
};
export const getVendorsWithSignedAgreement = async (publisherBrId: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.Vendor[]> => {
    return _client.httpFetch<DTO.Vendor[]>('GET', _client.resolveUrl(`/Publishers/${publisherBrId}/VendorsWithSignedAgreement`), null, $headers, uploadEvent, returnXhr);
};
export const updateAssignment = async (publisherBrId: string, assignment: DTO.AssignmentRequest, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.AssignmentRequest> => {
    return _client.httpFetch<DTO.AssignmentRequest>('PUT', _client.resolveUrl(`/Publishers/${publisherBrId}/Assignments`), assignment, $headers, uploadEvent, returnXhr);
};
export const updateSignedAgreement = async (publisherBrId: string, signedAgreementRequest: DTO.SignedAgreementRequest, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.SignedAgreementResponse> => {
    return _client.httpFetch<DTO.SignedAgreementResponse>('PUT', _client.resolveUrl(`/Publishers/${publisherBrId}/SignedAgreements`), signedAgreementRequest, $headers, uploadEvent, returnXhr);
};
export const uploadChunk = async (publisherBrId: string, sessionId: string, contentRange: string, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<DTO.UploadSessionResponse> => {
    return _client.httpFetch<DTO.UploadSessionResponse>('PUT', _client.resolveUrl(`/Publishers/${publisherBrId}/UploadChunk?sessionId=${encodeURIComponent((sessionId != null ? sessionId : ""))}`), contentRange, $headers, uploadEvent, returnXhr);
};
export const uploadContent = async (publisherBrId: string, isbn: string, contentTypeId: number, $headers?: { [name: string]: string | (() => string) }, uploadEvent?: (ev: ProgressEvent) => void, returnXhr: boolean = false): Promise<any> => {
    return _client.httpFetch<any>('POST', _client.resolveUrl(`/Publishers/${publisherBrId}/Books/${isbn}/Contents/${contentTypeId}`), null, $headers, uploadEvent, returnXhr);
};
