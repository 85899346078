import { rankItem } from "@tanstack/match-sorter-utils";

import type { ColumnDef, FilterFn } from "@tanstack/react-table";

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Fetch current value
  const cellValue = row.getValue(columnId);

  // If row depth is greater than zero or the current row has subrows,
  // that means that we're dealing with a nested table
  const isNested = row.depth > 0 || row.originalSubRows !== undefined;

  /*
  If we have a nested table and the current value is an empty string,
  we're probably dealing with trying to filter on a different level,
  i.e., given the table

  ┌───────┬───────┐
  │ Foo   │ Bar   │
  ├───────┼───────┤
  │ Val 1 │       │
  ├───────┼───────┤
  │       │ Val 2 │
  └───────┴───────┘

  Trying to filter on Bar would yield an empty value for the first row,
  which would fail to match. The block below handles this case.

  */
  if (isNested && cellValue === "") {
    // First, let's check if the column is configured so that a leaf value
    // *must* match the condition
    const { leafMustMatchFilter } = row.getAllCells().find((c) => c.column.id === columnId)?.column.columnDef.meta ?? {};

    // Leaf rows will always have an undefined originalSubRows
    if (row.originalSubRows === undefined) {
      // Just return the inverse of leafMustMatch (i.e, rows with leafMustMatchFilter = true will never match in this case)
      return !leafMustMatchFilter;
    }

    // Otherwise, recursively apply the filter to child rows and use that. In the above case, since Foo = "Val 1" has a child
    // row with Bar = "Val 2", it would match filters matching "Val 1" on Bar and filters matching "Val 2" on Bar
    return row.subRows.some((r) => fuzzyFilter(r, columnId, value, addMeta));
  }

  // Rank the item
  const itemRank = rankItem(cellValue, value);

  // Store the itemRank info
  addMeta({
    itemRank
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const defaultColumnObject = <T,>(): Partial<ColumnDef<T>> => {
  return {
    // filterFn: fuzzyFilter
  };
};

export default defaultColumnObject;
