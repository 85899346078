import { Form } from "react-bootstrap";

import type { Vendor, VendorSettingRequest } from "Src/api/Dto";

import { ItemValueType } from "Src/api/Enums";

interface Props {
  handleChangeSetting: (e: React.ChangeEvent<HTMLInputElement>) => void;
  vendorSettings: VendorSettingRequest[];
  vendor: Vendor;
}

export function VendorSettings({ handleChangeSetting, vendorSettings, vendor }: Props) {
  return (
    <>
      {vendorSettings?.map((setting, index) => {
        const vendorSettingResponse = vendor.vendorSettings[index];

        return (
          <div key={index}>
            <Form className="mb-3">
              <Form.Group>
                <Form.Label>
                  <strong>{`${setting.name} - ${vendorSettingResponse.description.replace(/\.$/, "")}`}</strong>
                </Form.Label>
              </Form.Group>
              <div className="col-md-5">
                <Form.Control
                  name={setting.name}
                  required={vendorSettingResponse.required}
                  type={vendorSettingResponse.itemValueType === ItemValueType.Secret ? "password" : "text"}
                  value={setting.value ?? ""}
                  onChange={handleChangeSetting}
                />
              </div>
            </Form>
          </div>
        );
      })}
    </>
  );
}
