import { flexRender } from "@tanstack/react-table";

import type { RTWrapperProps } from "../Interfaces/Interfaces";
import type { Cell, Row } from "@tanstack/react-table";

export interface RTCellProps<T> extends Pick<RTWrapperProps<T>, "disableGroupByRowCount"> {
  cell: Cell<T, unknown>;
  row: Row<T>;
}

export default function RtCell<T>({ cell, row, disableGroupByRowCount }: RTCellProps<T>) {
  // If it's a grouped cell, render cell with row count
  if (cell.getIsGrouped()) {
    return (
      <>
        {flexRender(cell.column.columnDef.cell, cell.getContext())} {disableGroupByRowCount ? "" : `- (${row.subRows.length})`}
      </>
    );
  }

  // In expanding mode the expander column will be marked as aggregated.
  // This makes sure the specific expander column is rendered as an ordinary cell and not as an aggregated,
  // otherwise the cells for the column will be empty.
  if (cell.getIsAggregated() && cell.column.id === "custom-expander") {
    return <>{flexRender(cell.column.columnDef.cell, cell.getContext())}</>;
  }

  // If the cell is aggregated, use the aggregated renderer for cell
  // if (cell.getIsAggregated()) {
  //   return <>{flexRender(cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell, cell.getContext())}</>;
  // }

  // If the cell is aggregated, use the aggregated renderer for cell
  // A cell is marked as aggregated in expanded AND grouped mode. Make sure to ONLY render aggregated cells in GROUPED mode.
  // If we render aggregated cells in expanded mode we will get empty cells in expander colummn.
  if (cell.getIsAggregated() && cell.getContext().table.getState().grouping.length > 0) {
    return <>{flexRender(cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell, cell.getContext())}</>;
  }

  // For cells with repeated values, render null
  if (cell.getIsPlaceholder()) {
    return null;
  }

  // Otherwise, just render the regular cell
  return <>{flexRender(cell.column.columnDef.cell, cell.getContext())}</>;
}
