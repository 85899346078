import { forwardRef, useEffect } from "react";
import { Stack } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker, { registerLocale } from "react-datepicker";
import { useError } from "@dekiru/react-error-boundary";
import sv from "date-fns/locale/sv";
import dayjs from "dayjs";

import type { Vendor } from "Src/api/Dto";
import type { SearchedBook } from "Src/components/Common/SearchBooks";
import type { ReactDatePickerRefProps } from "Src/interfaces/Interfaces";

import useUser from "Src/hooks/useUser";

import {
  clearAssignmentRequest,
  createUpdateAssignment,
  updateAssignmentRequest,
  updateAssignmentRequestProp
} from "Src/squawk/ActionsAssignmentRequest";
import { usePendingAssignmentRequest, useSquawkAssignmentRequest } from "Src/squawk/StoreAssignmentRequest";

import { formatDate } from "../../../../utils/Utils";

import PriceProperties from "./PriceProperties";
import Territories from "./Territories";

registerLocale("sv", sv);

interface Props {
  editMode: boolean;
  vendor: Vendor | undefined;
  onSelectBook?: (selectedBook: SearchedBook | undefined) => void;
  keepVendorBrIdOrIsbnOnCancel?: "isbn" | "vendorBrId";
}

// It's important that these two components are outside the parent component. Otherwise issues with focus occurs with date picker
const InputValidFrom = forwardRef((props: Partial<ReactDatePickerRefProps>, ref: React.ForwardedRef<HTMLInputElement>) => (
  <Form>
    <Form.Group>
      <Form.Label>
        <strong>Giltig från</strong>
      </Form.Label>
      <Form.Control ref={ref} {...props} name="validFrom" placeholder="YYYY-MM-DD" type="text" />
    </Form.Group>
  </Form>
));
const InputValidTo = forwardRef((props: Partial<ReactDatePickerRefProps>, ref: React.ForwardedRef<HTMLInputElement>) => (
  <Form>
    <Form.Group>
      <Form.Label>
        <strong>Giltig till och med</strong>
      </Form.Label>
      <Form.Control ref={ref} {...props} name="validTo" placeholder="YYYY-MM-DD" type="text" />
    </Form.Group>
  </Form>
));

export function FormCommon(props: Props) {
  const { editMode, onSelectBook, vendor, keepVendorBrIdOrIsbnOnCancel } = props;
  const { brId } = useUser();
  const { validFrom, validTo, vendorBrId, priceProperties, isbn, territoryAssignments } = useSquawkAssignmentRequest();

  const pricePropertiesRequired = vendor?.priceProperties.some((priceProperty) => priceProperty.required);
  const pricePropertiesEmptyValue = priceProperties.some((priceProperty) =>
    priceProperty.pricePropertyItems.some((pricePropertyItems) => pricePropertyItems.value === "")
  );

  const disabled =
    validFrom === "" ||
    validFrom === undefined ||
    validTo === "" ||
    validTo === undefined ||
    vendorBrId === "" ||
    isbn === "" ||
    (vendor?.territoryRequired && territoryAssignments.length === 0) ||
    (pricePropertiesRequired && pricePropertiesEmptyValue);
  const { vendorBrId: loading } = usePendingAssignmentRequest();
  const dispatchError = useError();

  useEffect(() => {
    return () => {
      clearAssignmentRequest();
    };
  }, []);

  const commonDatePickerProps = {
    dateFormat: "yyyy-MM-dd",
    locale: "sv",
    todayButton: "Idag",
    showWeekNumbers: true,
    showYearDropdown: true,
    showMonthDropdown: true
  };

  const cancelButton = () => {
    onSelectBook?.(undefined);
    // Covers the case where everything but the vendorBrId should be cleared when canceling the assignmentrequest
    if (keepVendorBrIdOrIsbnOnCancel === "vendorBrId") {
      updateAssignmentRequest({
        validFrom: "",
        validTo: "",
        vendorBrId: vendor?.vendorBrId,
        territoryAssignments: [],
        priceProperties: [],
        isbn: ""
      });
      // Covers the case where everything but the isbn should be cleared when canceling the assignmentrequest
    } else if (keepVendorBrIdOrIsbnOnCancel === "isbn") {
      updateAssignmentRequest({
        validFrom: "",
        validTo: "",
        vendorBrId: "",
        territoryAssignments: [],
        priceProperties: [],
        isbn
      });
    } else {
      clearAssignmentRequest();
    }
  };

  return (
    <>
      <Stack direction="horizontal" gap={2}>
        <div>
          <DatePicker
            customInput={<InputValidFrom value={validFrom} />}
            {...commonDatePickerProps}
            minDate={new Date()} // Dont allow user to select dates previous to the current date
            selected={validFrom ? new Date(validFrom) : undefined} // Updates the blue marked day in datepicker
            onChange={(date) => updateAssignmentRequestProp(formatDate(date), "validFrom")}
          />
        </div>
        <div>
          <DatePicker
            customInput={<InputValidTo />}
            {...commonDatePickerProps}
            minDate={validFrom ? new Date(validFrom) : new Date()} // Dont allow user to select dates previous to the current date or assigned validFrom date
            selected={validTo ? new Date(validTo) : undefined} // Updates the blue marked day in datedatepicker
            onChange={(date) => updateAssignmentRequestProp(formatDate(date), "validTo")}
          />
        </div>
      </Stack>
      {vendor?.territoryRequired && (
        <div className="pb-3">
          <Territories territoryAssignments={territoryAssignments} />
        </div>
      )}
      {vendor?.priceProperties?.map((priceProperty, i) => (
        <div key={priceProperty.name}>
          <PriceProperties pricePropertyRequest={priceProperties?.[i]} pricePropertyResponse={priceProperty} />
        </div>
      ))}
      <div className="pt-3">
        {editMode && (
          <Button className="me-1" disabled={loading} variant="secondary" onClick={cancelButton}>
            Avbryt
          </Button>
        )}
        <Button
          disabled={disabled || loading}
          onClick={() => {
            if (!dayjs(validFrom, "YYYY-MM-DD", true).isValid() || !dayjs(validTo ?? "", "YYYY-MM-DD", true).isValid()) {
              alert("Vänligen ange ett korrekt datum");

              return;
            } else if (dayjs(validTo ?? "").isBefore(dayjs(), "day")) {
              alert("Du har angett ett slutdatum före dagens datum. Vänligen ange ett slutdatum från tidigast dagens datum");

              return;
            }

            // Using catch() instead of try/catch because the latter requires an await which is unnecessary
            createUpdateAssignment(brId, editMode).catch((error) => {
              dispatchError(error);
            });
          }}
        >
          {editMode ? "Spara" : "Tilldela"}
        </Button>
      </div>
    </>
  );
}

export default FormCommon;
