import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import type { PricePropertyItemRequest, PricePropertyRequest, PricePropertyResponse } from "Src/api/Dto";

import { ItemValueType } from "Src/api/Enums";

import { updatePricePropertyItems } from "Src/squawk/ActionsAssignmentRequest";

interface Props {
  /**
   * Use request for retrieving actual values for price properties
   */
  pricePropertyRequest: PricePropertyRequest | undefined;

  /**
   * Use response for rendering all possible price properties
   */
  pricePropertyResponse: PricePropertyResponse;
}

type PriceProps = Record<string, string>;

export function PriceProperties({ pricePropertyResponse, pricePropertyRequest }: Props) {
  const { pricePropertyItems } = pricePropertyResponse;
  const [formPriceProps, setFormPriceProps] = useState<PriceProps>();

  useEffect(() => {
    // Convert price property items array to an object for key/value retrieval when rendering the form and the updating state
    const pricePropertyItemsObject: PriceProps | undefined = pricePropertyResponse?.pricePropertyItems?.reduce<PriceProps>((acc, curr) => {
      const val = pricePropertyRequest?.pricePropertyItems?.find((pricePropertyItemRequest) => pricePropertyItemRequest.name === curr.name)?.value;
      acc[curr.name] = val ?? "";

      return acc;
    }, {});

    if (!pricePropertyItemsObject) {
      return;
    }

    setFormPriceProps(pricePropertyItemsObject);
  }, [pricePropertyResponse]);

  const handleOnChange = (value: string, pricePropertyItemRequestName: string, itemValueType: ItemValueType) => {
    // Regex that only allows positive decimals with max 8 integers and two decimals (comma and dot decimal)
    // Ex: "123,12", "123.12", "123", "123,"
    // eslint-disable-next-line no-useless-escape
    const kontrollDecimal = /^\d{1,8}(\.\d{0,2}|\,\d{0,2})?$/;
    const kontrollInt = /^[0-9]+$/; // Regex som endast tillåter positiva numeriska värden. Ex "123"
    const kontrollCurrencyCode = /^[A-Z]{1,3}$/; // Regex som endast tillåter stora bokstäver och högst 3 tecken. Ex "SEK"

    if (pricePropertyItemRequestName === "CurrencyCode" && !kontrollCurrencyCode.test(value.trim()) && value !== "") {
      return; // Return if the value doesn't match ISO 4217 currency code format
    }

    if (itemValueType === ItemValueType.Numeric && !kontrollInt.test(value) && value !== "") {
      return;
    }

    if (itemValueType === ItemValueType.Decimal && !kontrollDecimal.test(value) && value !== "") {
      return;
    }

    // Assign to new variable to make sure we use latest state values below
    const updatedPriceProps = { ...formPriceProps, [pricePropertyItemRequestName]: value };
    setFormPriceProps(updatedPriceProps);

    const pricePropertyItemRequest: PricePropertyItemRequest[] = Object.keys(updatedPriceProps).map((key) => ({
      name: key,
      value: updatedPriceProps[key].replace(",", ".") // Backend only handles "." as decimal
    }));

    updatePricePropertyItems(pricePropertyResponse.name, pricePropertyItemRequest);
  };

  const valueType = (itemValueType: ItemValueType) => {
    switch (itemValueType) {
      case ItemValueType.Currency:
        return "Currency code (Eg: SEK/USD/EUR)";
      case ItemValueType.Decimal:
        return "Decimal";
      case ItemValueType.Numeric:
        return "Numeric";
      case ItemValueType.Secret:
        return "Secret";
      case ItemValueType.Text:
        return "Text";
      default:
        return "";
    }
  };

  const placeholderText = (itemValueType: ItemValueType) => {
    switch (itemValueType) {
      case ItemValueType.Currency:
        return "Eg: SEK/USD/EUR";
      case ItemValueType.Decimal:
        return "0,0";
      case ItemValueType.Numeric:
        return "0";
      case ItemValueType.Secret:
        return "*****";
      case ItemValueType.Text:
        return "ABC";
      default:
        return "";
    }
  };

  return (
    <>
      <h4 className="pb-3 pt-2">
        {pricePropertyResponse.name} - {pricePropertyResponse.description}
      </h4>
      {pricePropertyItems?.map((price) => (
        <Form key={price.name} className="mb-3">
          <Form.Group>
            <Form.Label>
              <strong>{`${price.description} - ${valueType(price.itemValueType)}`}</strong>
            </Form.Label>
          </Form.Group>
          <Form.Control
            name={price.name}
            placeholder={placeholderText(price.itemValueType)}
            required={price.required}
            type={price.name === "PriceAmount" ? "number" : "text"}
            value={formPriceProps ? formPriceProps[price.name] : ""}
            onChange={(e) => handleOnChange(e.target.value, price.name, price.itemValueType)}
          />
        </Form>
      ))}
    </>
  );
}

export default PriceProperties;
