import { Container } from "react-bootstrap";
import { DebugPanel } from "@dekiru/debug-panel";

import type { MenuItem } from "./BokinfoHeaderMenu";
import type { PropsWithChildren } from "react";

import { UserType } from "Src/api/Enums";

import useUser from "Src/hooks/useUser";

import { ErrorBoundaryModal } from "../Common/ErrorBoundaryModal";

import SwitchUser from "./Development/SwitchUser";
import BokinfoHeaderMenu from "./BokinfoHeaderMenu";

const menuItemsAdmin: MenuItem[] = [
  { name: "Adminvy Böcker", url: "/admin/publisherbooks" },
  { name: "KPI", url: "/admin/kpi" },
  { name: "Loggar", url: "/admin/logs" }
];

const menuItemsPublisher: MenuItem[] = [
  {
    name: "Böcker",
    url: "/publisher/books/book-list",
    subItems: [
      { name: "Hantera digitala böcker", url: "/publisher/books/book-list" },
      { name: "Tilldela återförsäljare till bok", url: "/publisher/books/vendor-list" },
      { name: "Hantera media", url: "/publisher/upload" },
      { name: "Hantera tilldelning", url: "/publisher/books/assignment" }
    ]
  },
  { name: "Återförsäljare", url: "/publisher/vendors" }
];

export function Wrapper({ children }: PropsWithChildren<{}>) {
  const user = useUser();

  const menuItems = user.type === UserType.Admin ? menuItemsAdmin : menuItemsPublisher;

  return (
    <>
      <BokinfoHeaderMenu menuItems={menuItems} />
      <main>
        <ErrorBoundaryModal>
          <DebugPanel enablePanel={import.meta.env.NODE_ENV === "development"} widgets={[<SwitchUser key="debug-switch-user" />]} />
          <Container>{children}</Container>
        </ErrorBoundaryModal>
      </main>
    </>
  );
}
