import Button from "react-bootstrap/button";
import Modal from "react-bootstrap/Modal";

import type { Agreement, SignedAgreementResponse, Vendor } from "Src/api/Dto";

import { AgreementType } from "Src/api/Enums";

import useToggle from "Src/hooks/useToggle";

import { Apple } from "./Texts/Apple";
import { Axiell } from "./Texts/Axiell";
import { Boksnok } from "./Texts/Boksnok";
import { BoksyStabenfeldt } from "./Texts/BoksyStabenfeldt";
import { BookBeat } from "./Texts/BookBeat";
import { Booksquare } from "./Texts/Booksquare";
import { Google } from "./Texts/Google";
import { Nextory } from "./Texts/Nextory";
import { Overdrive } from "./Texts/Overdrive";
import { Publit } from "./Texts/Publit";
import { Readly } from "./Texts/Readly.Com";
import { StoryTel } from "./Texts/Storytel";

interface Props {
  signedAgreement: SignedAgreementResponse | undefined;
  vendor: Vendor;
  agreement?: Agreement;
}

export function ShowAgreementText({ vendor, signedAgreement, agreement }: Props) {
  const [modalOpen, toggleModalOpen] = useToggle();
  const isSigned = signedAgreement ? true : false;

  const renderTrigger = () => {
    if (!isSigned && agreement) {
      return (
        <a
          href="#"
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            toggleModalOpen();
          }}
        >
          {agreement && agreement.name}
        </a>
      );
    }

    if (signedAgreement?.agreementType === AgreementType.Standard) {
      return (
        <Button size="sm" variant="secondary" onClick={() => toggleModalOpen()}>
          Visa avtal
        </Button>
      );
    }

    return "-";
  };

  const getAgreementText = () => {
    const { vendorBrId } = vendor;

    switch (vendorBrId) {
      case "10070":
        return <Apple />;
      case "20536":
        return <Apple />;
      case "8011":
        return <BookBeat />;
      case "10069":
        return <Google />;
      case "20537":
        return <Google />;
      case "7594":
        return <Nextory />;
      case "20538":
        return <Overdrive />;
      case "6811":
        return <Readly />;
      case "4744":
        return <StoryTel />;
      case "20363":
        return <Publit />;
      case "20380":
        return <Booksquare />;
      case "20424":
        return <BoksyStabenfeldt />;
      case "20433":
        return <Boksnok />;
      case "4849":
        return <Axiell />;
      default:
        return null;
    }
  };

  return (
    <>
      {renderTrigger()}
      <Modal show={modalOpen} size="lg">
        <Modal.Header>
          <Modal.Title>
            {vendor.name} {signedAgreement?.agreementType === AgreementType.Standard ? "standardavtal" : "befientligt avtal"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{getAgreementText()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModalOpen}>
            Stäng
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
