import type { GroupingState, Row } from "@tanstack/react-table";

export const getHasExpandableRows = <T>(
  grouping: GroupingState,
  renderSubRowComponent: ((row: Row<T>) => JSX.Element) | undefined,
  getSubRows: ((originalRow: T, index: number) => T[] | undefined) | undefined,
  data: T | T[] | object | undefined
) => {
  const hasSubRowsInData = data
    ? Object.entries(data).some(([_key, value]) => {
        if (typeof value === "object" && value !== null && Object.hasOwn(value, "subRows")) {
          return value.subRows?.length > 0;
        }

        return false;
      })
    : false;
  const hasCreatedSubRows = getSubRows !== undefined;
  const hasGroupedRows = grouping.length > 0;
  const hasSubRowComponent = renderSubRowComponent !== undefined;

  return hasSubRowsInData || hasCreatedSubRows || hasGroupedRows || hasSubRowComponent;
};
