import { Helmet } from "react-helmet";

import { ErrorBoundaryModal } from "./Common/ErrorBoundaryModal";
import { UserProvider } from "./Context/UserProvider";
import BokinfoFooter from "./General/BokinfoFooter";
import { Wrapper } from "./General/Wrapper";

interface Props {
  children?: React.ReactNode;
}

export function Layout({ children }: Props) {
  return (
    <>
      <Helmet defaultTitle={import.meta.env.WP_TITLE} titleTemplate={`%s | ${import.meta.env.WP_TITLE}`} />
      <ErrorBoundaryModal>
        <UserProvider>
          <Wrapper>{children}</Wrapper>
          <BokinfoFooter />
        </UserProvider>
      </ErrorBoundaryModal>
    </>
  );
}
