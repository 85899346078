import { Alert } from "react-bootstrap";

interface Props {
  title?: string;
  content?: JSX.Element | string;
}

export default function CustomAlert({ title, content }: Props) {
  return (
    <Alert className="text-dark d-inline-block" variant="light">
      {title && <Alert.Heading>{title}</Alert.Heading>}
      {content}
    </Alert>
  );
}
