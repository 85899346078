import { useEffect, useState } from "react";
import { Row, Spinner } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useLocation } from "react-router";
import { useError } from "@dekiru/react-error-boundary";

import type { AssignmentResponse, Book } from "Src/api/Dto";
import type { SearchedBook } from "Src/components/Common/SearchBooks";

import { getPublisherBookAssignments } from "Src/api/Publishers";

import useUser from "Src/hooks/useUser";

import { clearAssignmentRequest, updateAssignmentRequest, updateAssignmentRequestProp } from "Src/squawk/ActionsAssignmentRequest";
import { usePendingAssignmentRequest } from "Src/squawk/StoreAssignmentRequest";

import { Form } from "./Form";
import { List } from "./List";

interface State {
  loading: boolean;
  assignments: AssignmentResponse[] | undefined; // use undefined to differentiate between no result and empty result/array
}

export function Index() {
  const { brId } = useUser();
  const { vendorBrId: squawkStoreLoading } = usePendingAssignmentRequest();
  const { state: locationState } = useLocation<{ book: Book } | null>();
  const [searchedBook, setSearchedBook] = useState<SearchedBook | undefined>();
  const [{ loading, assignments }, setState] = useState<State>({
    loading: false,
    assignments: undefined
  });
  const dispatchError = useError();

  const onSelectBook = (selectedBook: SearchedBook | undefined) => {
    setSearchedBook(selectedBook);
    updateAssignmentRequestProp("", "vendorBrId");
  };

  const isbnUrl = locationState && locationState.book ? locationState.book.isbn : "";
  const isbn = searchedBook?.isbn || isbnUrl;
  const showSearchBook = isbnUrl === "" || isbnUrl === undefined;

  useEffect(() => {
    if (isbn) {
      getBookVendors();
      updateAssignmentRequest({
        validFrom: "",
        validTo: "",
        vendorBrId: "",
        territoryAssignments: [],
        priceProperties: [],
        isbn
      });
    } else {
      setState({ loading: false, assignments: [] });
      clearAssignmentRequest();
    }
  }, [isbn, showSearchBook]);

  useEffect(() => {
    if (!squawkStoreLoading && isbn) {
      getBookVendors();
    }
  }, [squawkStoreLoading]);

  const getBookVendors = async () => {
    try {
      setState((state) => ({ ...state, loading: true }));
      const assigments = await getPublisherBookAssignments(brId, isbn);
      setState((state) => ({ ...state, assignments: assigments, loading: false }));
    } catch (error) {
      dispatchError(error);
    }
  };

  return (
    <Card className="p-4">
      {locationState && locationState.book && (
        <h2 className="pb-4">
          Återförsäljare för {locationState.book.title}/{locationState.book.bookTypeName} {isbnUrl}
        </h2>
      )}
      <Form assignments={assignments} loading={loading} searchedBook={searchedBook} onSelectBook={onSelectBook} />
      {loading || squawkStoreLoading ? (
        <Row className="justify-content-center">
          <Spinner animation="border" />
        </Row>
      ) : (
        <div className="pt-5">
          <List assignments={assignments ?? []} />
        </div>
      )}
    </Card>
  );
}
