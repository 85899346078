import { Fragment } from "react";
import { Table } from "react-bootstrap";

import type { RTWrapperHelperProps, RTWrapperProps } from "./ReactTableWrapper";
import type { Table as ReactTableProps } from "@tanstack/react-table";
import type { TableProps } from "react-bootstrap";

import RtLoadingBody from "./RtLoadingBody";
import RtLoadingRow from "./RtLoadingRow";
import RtPagination from "./RtPagination";
import RtTextFilter from "./RtTextFilter";

interface Props<T> extends Pick<RTWrapperProps<T>, "renderSubRowComponent"> {
  reactTableInstance: ReactTableProps<T>;
  helperProps: RTWrapperHelperProps;
  loading?: boolean;
  bootstrapTableProps?: TableProps;
  customTableProps?: {
    initialAllRowsExpanded?: boolean;
    collapseTable?: boolean;
  };
  enablePagination?: boolean;
}

export default function RtBootstrapTable<T>({
  enablePagination,
  reactTableInstance: instance,
  helperProps: { RenderHeaderWrapper, RenderCellWrapper },
  customTableProps: _customTableProps,
  bootstrapTableProps,
  ...restProps
}: Props<T>) {
  const getTextAlign = (align?: "center" | "char" | "justify" | "left" | "right") => {
    switch (align) {
      case "center":
        return "text-center";
      case "right":
        return "text-end";
      case "left":
        return "text-start";
      default:
        return "";
    }
  };

  return (
    <>
      <Table {...bootstrapTableProps}>
        <thead>
          {instance.getHeaderGroups().map((headerGroup, index) => (
            <Fragment key={headerGroup.id}>
              <tr>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={`header-row ${header.column.columnDef.meta?.className ?? ""} ${getTextAlign(header.column.columnDef.meta?.align)}`}
                    colSpan={header.colSpan}
                    data-column-index={header.index}
                    style={{ width: header.column.columnDef.meta?.width, ...header.column.columnDef.meta?.style }}
                  >
                    <RenderHeaderWrapper header={header} />
                  </th>
                ))}
              </tr>
              {/* Make to render filter row once as last row */}
              {instance.getCoreRowModel().rows.length > 0 &&
                headerGroup.headers.some((header) => header.column.getCanFilter()) &&
                index === instance.getHeaderGroups().length - 1 && (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        align={header.column.columnDef.meta?.align}
                        className={`header-row-filter ${header.column.columnDef.meta?.className ?? ""} ${getTextAlign(
                          header.column.columnDef.meta?.align
                        )}`}
                        colSpan={header.colSpan}
                        style={{ width: header.column.columnDef.meta?.width, ...header.column.columnDef.meta?.style }}
                      >
                        {header.column.getCanFilter() && <RtTextFilter column={header.column} instance={instance} />}
                      </th>
                    ))}
                  </tr>
                )}
            </Fragment>
          ))}
        </thead>
        {/* Loader shown when there is no initial/previous data. Usually occurs during initial/first rendering of the table  */}
        {restProps.loading && instance.getCoreRowModel().flatRows.length === 0 ? (
          <RtLoadingBody renderOverlay={false} visibleColumns={instance.getVisibleFlatColumns()} />
        ) : (
          <tbody style={{ position: restProps.loading ? "relative" : undefined }}>
            {instance.getRowModel().rows.map((row) => (
              <Fragment key={row.id}>
                <tr>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={`${cell.column.columnDef.meta?.className} ${getTextAlign(cell.column.columnDef.meta?.align)}`}
                      style={{ width: cell.column.columnDef.meta?.width, ...cell.column.columnDef.meta?.style }}
                    >
                      <RenderCellWrapper cell={cell} row={row} />
                    </td>
                  ))}
                </tr>
                {row.getIsExpanded() && restProps.renderSubRowComponent && (
                  <tr>
                    <td />
                    <td colSpan={instance.getVisibleFlatColumns().length - 1}>{restProps.renderSubRowComponent(row)}</td>
                  </tr>
                )}
              </Fragment>
            ))}
            {restProps.loading && <RtLoadingRow renderOverlay visibleColumns={instance.getVisibleFlatColumns()} />}
          </tbody>
        )}
      </Table>
      {enablePagination && instance.getCoreRowModel().flatRows.length > 0 && <RtPagination instance={instance} />}
    </>
  );
}
