export function BoksyStabenfeldt() {
  return (
    <div id="lipsum">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lectus risus, dignissim in dignissim ac, scelerisque vel justo. Donec pulvinar
        purus eget nunc fringilla venenatis. Praesent ut nisi massa. Nunc bibendum metus nulla, sed commodo mi placerat a. Donec sem tortor, interdum
        et orci ac, bibendum tristique quam. Sed malesuada est quis dui condimentum pretium. Mauris euismod tellus nec ante dignissim, a luctus purus
        vestibulum. Vestibulum ut lacinia urna. Sed ultricies bibendum iaculis. Vestibulum interdum maximus rutrum. Curabitur tristique ullamcorper
        turpis, at scelerisque nisi faucibus sit amet.
      </p>
      <p>
        Mauris gravida id nisl vel condimentum. Etiam interdum fringilla sapien non rutrum. Morbi id nunc pellentesque, fringilla quam in, venenatis
        nulla. Mauris egestas sapien enim, in pretium neque varius vitae. Cras fringilla lorem sed bibendum tincidunt. Cras mauris arcu, volutpat in
        nunc id, porttitor porta tellus. Donec faucibus, enim volutpat pulvinar maximus, lectus nunc tempus orci, eu suscipit purus orci ac ante.
        Phasellus tristique, ante sit amet mattis maximus, felis risus laoreet ex, at tempus lorem metus at nunc. Nunc ut porttitor justo. Curabitur
        ac feugiat dui. Nam ullamcorper commodo ex quis vehicula.
      </p>
      <p>
        Duis ac porta neque. Phasellus et efficitur arcu, eget pellentesque quam. Nunc iaculis urna et augue varius, fermentum eleifend urna commodo.
        Pellentesque mattis enim ac gravida hendrerit. Sed vel velit massa. Nam mi sapien, dapibus dapibus blandit et, dapibus et sem. Vestibulum ante
        ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Aenean eget volutpat ligula, ac maximus eros. Phasellus at
        sollicitudin sem, eu tempor tortor. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent rhoncus
        placerat ex, non ultrices nisi semper eget. Donec rutrum semper erat nec feugiat. Phasellus ultrices augue a pharetra hendrerit. Fusce dictum
        est neque, sit amet ornare diam rhoncus sollicitudin. Sed viverra leo ut magna molestie, sit amet facilisis quam volutpat.
      </p>
      <p>
        Aenean efficitur erat eget orci efficitur, in pulvinar odio tempus. Vestibulum sit amet efficitur turpis. Morbi vestibulum vel sem et
        condimentum. Praesent mollis felis felis, id rhoncus erat pellentesque in. Quisque viverra vestibulum placerat. Aenean volutpat dolor ligula,
        eget tempus diam congue ac. Nulla dapibus euismod massa, ac rhoncus diam porta et. Aliquam varius erat non egestas pretium. Etiam iaculis
        justo ac nisi laoreet, vel dapibus justo congue. Vivamus quis fermentum orci, id imperdiet ligula. Donec ac tincidunt eros, a dignissim nulla.
        Sed bibendum nisl ac ultrices feugiat. Donec eget ante rhoncus, consequat lorem at, accumsan diam. Nulla non massa non massa rhoncus maximus.
        In luctus at mi porttitor pharetra. Maecenas a risus non velit vehicula dignissim et non justo.
      </p>
      <p>
        Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nam eget lacinia sapien, ac tincidunt dui. Morbi
        eu faucibus tellus. Nulla fermentum nibh eu ipsum lacinia iaculis. Quisque dapibus, erat quis dignissim sodales, est nunc sodales turpis, at
        sodales ante felis in tortor. Cras bibendum, neque sit amet scelerisque imperdiet, augue metus rhoncus ipsum, vitae mattis nibh velit rutrum
        elit. Suspendisse commodo elit vestibulum pharetra mattis. Nunc ac suscipit lacus, sed facilisis mi. Duis finibus orci vitae sem laoreet
        pharetra eu sed dolor. Phasellus tincidunt porta dolor quis pulvinar. Proin non placerat velit. In hac habitasse platea dictumst. Donec
        venenatis vestibulum massa mattis volutpat.
      </p>
    </div>
  );
}
