import { Route } from "react-router-dom";

import { Index as KPI } from "../components/AdminUser/KPI/Index";
import { Index as Logs } from "../components/AdminUser/Logs/Index";
import { Index as PublisherBooks } from "../components/AdminUser/PublisherBooks/Index";
import { ErrorBoundaryModal } from "../components/Common/ErrorBoundaryModal";
import { Layout } from "../components/Layout";
import { Index as VendorAssignments } from "../components/Publisher/Books/ListBooks/Assignments/Index";
import { Index as ListBooks } from "../components/Publisher/Books/ListBooks/Index";
import { Index as BookAssignments } from "../components/Publisher/Books/ListVendors/Assignment/Index";
import { Index as ListVendors } from "../components/Publisher/Books/ListVendors/Index";
import { Index as BookUpload } from "../components/Publisher/Books/Uploads/Index";
import { CreateEditAgreement } from "../components/Publisher/Vendors/Agreements/CreateEditAgreement";
import { Index as Vendors } from "../components/Publisher/Vendors/Index";

const Routes = (
  <Layout>
    <Route exact component={Vendors} path="/publisher/vendors" />
    <Route
      exact
      component={() => (
        <ErrorBoundaryModal type="redo">
          <CreateEditAgreement />
        </ErrorBoundaryModal>
      )}
      path="/publisher/vendors/agreement"
    />
    <Route exact component={ListBooks} path="/publisher/books/book-list" />
    <Route
      exact
      component={() => (
        <ErrorBoundaryModal type="redo">
          <VendorAssignments />
        </ErrorBoundaryModal>
      )}
      path="/publisher/books/book-list/assignment/:isbn"
    />
    <Route
      exact
      component={() => (
        <ErrorBoundaryModal type="redo">
          <BookUpload />
        </ErrorBoundaryModal>
      )}
      path="/publisher/books/book-list/upload/:isbn"
    />
    <Route
      exact
      component={() => (
        <ErrorBoundaryModal type="redo">
          <VendorAssignments />
        </ErrorBoundaryModal>
      )}
      path="/publisher/books/assignment"
    />
    <Route
      exact
      component={() => (
        <ErrorBoundaryModal type="redo">
          <BookAssignments />
        </ErrorBoundaryModal>
      )}
      path="/publisher/books/vendor-list/assignment/:vendorBrId"
    />
    <Route exact component={ListVendors} path="/publisher/books/vendor-list" />
    <Route exact component={PublisherBooks} path="/admin/publisherbooks" />
    <Route exact component={KPI} path="/admin/kpi" />
    <Route exact component={Logs} path="/admin/logs" />
    <Route
      exact
      component={() => (
        <ErrorBoundaryModal type="redo">
          <BookUpload />
        </ErrorBoundaryModal>
      )}
      path="/publisher/upload/:isbn?"
    />
  </Layout>
);

export default Routes;
