import { Alert, Col, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import FormBs from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useHistory } from "react-router-dom";

import type { SearchedBook } from "../../../Common/SearchBooks";
import type { FormValues } from "./Index";
import type { Book, ContentType } from "Src/api/Dto";
import type { DropdownOptions } from "Src/interfaces/Interfaces";

import { SearchBooks } from "../../../Common/SearchBooks";

interface Props {
  book: Book;
  formValues: FormValues;
  updateForm: (value: string, name: string) => void;
  contentTypesOpt: DropdownOptions[];
  uploadBook: (e: React.FormEvent<HTMLFormElement>) => void;
  updateFile: (value: File) => void;
  bookTypeText: string;
  selectedBook?: SearchedBook;
  onSelectBook?: (selectedBook: SearchedBook | undefined) => void;
  isWrongFileType: boolean;
  setIsWrongFileType: (value: React.SetStateAction<boolean>) => void;
  uploadedContentTypes: ContentType[];
}

export function Form(props: Props) {
  const {
    book,
    contentTypesOpt,
    uploadBook,
    updateForm,
    formValues,
    updateFile,
    bookTypeText,
    selectedBook,
    onSelectBook,
    isWrongFileType,
    setIsWrongFileType,
    uploadedContentTypes
  } = props;
  const { filename } = formValues;
  const history = useHistory();

  return (
    <Container className="ms-0">
      <Row>
        <Col className="mb-3 mb-md-0" md="6">
          <h2 className="pb-3">Ladda upp fil</h2>
          <FormBs noValidate onSubmit={(e) => uploadBook(e)}>
            <FormBs.Group className="mb-3">
              <FormBs.Label>
                <strong>ISBN</strong>
              </FormBs.Label>
              <SearchBooks searchedBook={selectedBook} onSelectBook={onSelectBook} />
            </FormBs.Group>
            <FormBs.Group className="mb-3">
              <FormBs.Label>
                <strong>Bandtyp</strong>
              </FormBs.Label>
              <FormBs.Control disabled placeholder={bookTypeText} type="text" />
            </FormBs.Group>
            <FormBs.Group className="mb-3">
              <FormBs.Label>
                <strong>Filtyp</strong>
              </FormBs.Label>
              <FormBs.Select
                disabled={book.contentTypeIdRestriction && book.contentTypeIdRestriction > 0 ? true : false}
                onChange={(e) => updateForm(e.target.value, "contentTypeId")}
              >
                {contentTypesOpt.map((contentType) => (
                  <option key={contentType.key} value={contentType.value}>
                    {contentType.text}
                  </option>
                ))}
              </FormBs.Select>
            </FormBs.Group>
            <FormBs.Group className="mb-3">
              <FormBs.Label>
                <strong>Fil</strong>
              </FormBs.Label>
              <FormBs.Control
                accept=".zip"
                isInvalid={isWrongFileType}
                type="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files && e.target.files.length) {
                    updateFile(e.target.files[0]);
                    setIsWrongFileType(false);
                  }
                }}
              />
              <FormBs.Control.Feedback type="invalid">Filen måste vara av typen .zip</FormBs.Control.Feedback>
            </FormBs.Group>
            <Button className="w-100 mt-3" disabled={filename === "Välj en fil"} type="submit">
              Ladda upp och spara
            </Button>
          </FormBs>
          {uploadedContentTypes.length > 0 && (
            <Button className="w-100 mt-3" onClick={() => history.push(`/publisher/books/book-list/assignment/${book.isbn}`, { book })}>
              Tilldela bok till återförsäljare
            </Button>
          )}
        </Col>
        <Col className="d-flex align-items-end" md="6">
          <Alert className="mb-0" variant="info">
            <Alert.Heading>För zipfilen som ska laddas upp gäller: </Alert.Heading>
            <ul>
              <li>Underliggande filer får inte innehålla filnamn med åäö eller specialtecken</li>
              <li>Den zippade mappen får inte innehålla undermappar</li>
              <li>Om zippen skapas på Mac så får den färdiga zippen inte innehålla en Mac-specifik undermapp som heter `__MACOSX`</li>
            </ul>
            <div>
              Vid kapitelindelat ljud ska de underliggande filerna namnges enligt följande:
              <br />
              001_&lt;isbn&gt;.wav / 001_&lt;isbn&gt;.mp3
              <br />
              002_&lt;isbn&gt;.wav / 002_&lt;isbn&gt;.mp3
              <br />
              <br />
              För enstaka wav, mp3, epub eller PDF ska de underliggande filerna namnges enligt följande:
              <br />
              &lt;isbn&gt;.mp3 / &lt;isbn&gt;.wav / &lt;isbn&gt;.epub / &lt;isbn&gt;.pdf
            </div>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
}
