import { Col, Form, Pagination, Row, Stack } from "react-bootstrap";

import type { Table } from "@tanstack/react-table";

interface Props<T> {
  instance: Table<T>;
}

export default function RtPagination<T>({ instance }: Props<T>) {
  return (
    <Row className="justify-content-center justify-content-sm-end align-items-center gap-3 gap-sm-0">
      <Col md="auto" xs={12}>
        <Form.Select
          size="sm"
          value={instance.getState().pagination.pageSize}
          onChange={(e) => {
            instance.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Visa {pageSize} rader per sida
            </option>
          ))}
        </Form.Select>
      </Col>
      <Col md="auto" xs={12}>
        <Stack direction="horizontal" gap={2}>
          <span>Sida</span>
          <Form.Control
            size="sm"
            type="number"
            value={instance.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              instance.setPageIndex(page);
            }}
          />
          <span className="text-nowrap ">av {instance.getPageCount()}</span>
        </Stack>
      </Col>
      <Col xs="auto">
        <Pagination className="mb-0">
          <Pagination.First disabled={!instance.getCanPreviousPage()} onClick={() => instance.setPageIndex(0)} />
          <Pagination.Prev disabled={!instance.getCanPreviousPage()} onClick={() => instance.previousPage()} />
          <Pagination.Next disabled={!instance.getCanNextPage()} onClick={() => instance.nextPage()} />
          <Pagination.Last disabled={!instance.getCanNextPage()} onClick={() => instance.setPageIndex(instance.getPageCount() - 1)} />
        </Pagination>
      </Col>
    </Row>
  );
}
