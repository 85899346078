import { Button, Modal } from "react-bootstrap";
import { BsExclamationTriangle } from "react-icons/bs";
import { ReactErrorBoundary } from "@dekiru/react-error-boundary";

import CustomAlert from "./CustomAlert";

interface Props {
  children: React.ReactNode;
  type?: "modal" | "redo";
}

export function ErrorBoundaryModal({ children, type }: Props) {
  return (
    <ReactErrorBoundary
      render={({ errorMessage, closeError, DevExtraInfo, hasError }) => {
        if (type === "modal") {
          return (
            <Modal show={hasError} size="lg">
              <Modal.Header>
                <Modal.Title>
                  <span className="modal-title-icon me-1">
                    <BsExclamationTriangle color="red" />
                  </span>
                  Ett fel har uppstått
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p style={{ whiteSpace: "pre-wrap" }}>{errorMessage}</p>
                <DevExtraInfo />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeError}>
                  Stäng
                </Button>
              </Modal.Footer>
            </Modal>
          );
        }

        return (
          <CustomAlert
            content={
              <>
                <p style={{ whiteSpace: "pre-wrap" }}>{errorMessage}</p>
                <DevExtraInfo />
                <Button variant="secondary" onClick={closeError}>
                  Stäng
                </Button>
              </>
            }
            title="Ett fel har uppstått"
          />
        );
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
}
