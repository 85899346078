import { useEffect } from "react";
import { BsArrowDown } from "react-icons/bs";
import { BsArrowUp } from "react-icons/bs";
import { BsChevronRight } from "react-icons/bs";
import { BsChevronDown } from "react-icons/bs";

import type { RTWrapperProps } from "./ReactTableWrapper";
import type { TableProps } from "react-bootstrap";

import { useReactTableWrapper } from "./ReactTableWrapper";
import RtBootstrapTable from "./RtBootstrapTable";
import RtGlobalFilter from "./RtGlobalFilter";

interface Props<T> extends RTWrapperProps<T> {
  selectMenuProps?: {
    buttonText: string;
    onSelectFunction: (value: string, data: T[]) => void;
  };
  enableGrouping?: boolean;
  enableExport?: boolean;
  loading?: boolean;
  initialAllRowsExpanded?: boolean;
  bootstrapTableProps?: TableProps;
  customTableProps?: {
    initialAllRowsExpanded?: boolean;
    collapseTable?: boolean;
  };
  tableTitle?: string;
  fitToContent?: boolean;
}

export default function RtTable<T>(props: Props<T>) {
  const { bootstrapTableProps, fitToContent, ...restProps } = props;
  const { helperProps, reactTableInstance: instance } = useReactTableWrapper({
    ...restProps,
    disableTable: props.loading,
    defaults: {
      expandColumnIcons: {
        expandedIcon: <BsChevronDown />,
        collapsedIcon: <BsChevronRight />
      },
      sortColumnIcons: {
        sortedIconDefault: <span />,
        sortedIconAsc: <BsArrowDown />,
        sortedIconDesc: <BsArrowUp />
      }
    }
  });

  useEffect(() => {
    if (props.customTableProps?.initialAllRowsExpanded) {
      instance.toggleAllRowsExpanded(true);
    }
  }, [instance, props.customTableProps?.initialAllRowsExpanded]);

  if (props.useReactTableProps?.data === undefined) {
    return null;
  }

  return (
    <div className={fitToContent ? "rt-table__container" : undefined}>
      <div className="d-flex justify-content-left justify-content-sm-end mb-2">
        <RtGlobalFilter disabled={restProps.loading} reactTableInstance={instance} />
      </div>
      <RtBootstrapTable
        bootstrapTableProps={bootstrapTableProps}
        customTableProps={props.customTableProps}
        enablePagination={restProps.enablePagination !== undefined}
        helperProps={helperProps}
        loading={props.loading}
        reactTableInstance={instance}
        renderSubRowComponent={restProps.renderSubRowComponent}
      />
    </div>
  );
}
