import { Card } from "react-bootstrap";
import { Helmet } from "react-helmet";

import List from "./List";

export function Index() {
  return (
    <>
      <Helmet title="Återförsäljare" />
      <Card className="p-4 mt-2">
        <h2 className="pb-4">Återförsäljare</h2>
        <List />
      </Card>
    </>
  );
}
