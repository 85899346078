import { useMemo } from "react";
import Button from "react-bootstrap/Button";
import { BsPencilFill, BsXLg } from "react-icons/bs";
import { useError } from "@dekiru/react-error-boundary";
import { createColumnHelper } from "@tanstack/react-table";

import type { AssignmentRequest, AssignmentResponse, Vendor } from "Src/api/Dto";

import RtTable from "Src/components/Common/Table/RtTable";

import useUser from "Src/hooks/useUser";

import { deactivateAssignment, updateAssignmentRequest } from "Src/squawk/ActionsAssignmentRequest";
import { useSquawkApp } from "Src/squawk/StoreApp";

import { removeDateClock, transformPricePropertiesResponse } from "../../../../../utils/Utils";
import { IsActiveCommon } from "../../Common/IsActiveCommon";

const columnHelper = createColumnHelper<AssignmentResponse>();

interface Props {
  assignments: AssignmentResponse[];
}

export function List({ assignments }: Props) {
  const { brId } = useUser();
  const { signedVendors } = useSquawkApp();
  const dispatchError = useError();

  const createAssignmentRequest = (assignment: AssignmentResponse, vendor: Vendor) => {
    const assignmentRequest: AssignmentRequest = {
      isbn: assignment.isbn,
      validFrom: removeDateClock(assignment.validFrom),
      validTo: removeDateClock(assignment.validTo ?? ""),
      vendorBrId: assignment.vendorBrId,
      territoryAssignments: assignment.territoryAssignments,
      priceProperties: transformPricePropertiesResponse(vendor.priceProperties, assignment.priceProperties),
      distributionProperties: assignment.distributionProperties
    };

    return assignmentRequest;
  };

  const columns = useMemo(
    () => [
      columnHelper.display({
        header: "Aktiv",
        meta: {
          align: "center"
        },
        cell: (cell) => <IsActiveCommon validTo={cell.row.original.validTo ?? ""} />
      }),
      columnHelper.accessor(
        (data) => {
          return signedVendors.find((vendor) => vendor.vendorBrId === data.vendorBrId)?.name;
        },
        {
          header: "Återförsäljare "
        }
      ),
      columnHelper.accessor((data) => removeDateClock(data.validFrom), {
        header: "Giltig från"
      }),
      columnHelper.accessor((data) => removeDateClock(data.validTo ?? ""), {
        header: "Giltig till och med"
      }),
      columnHelper.display({
        header: "Uppdatera tilldelning",
        meta: {
          align: "center"
        },
        cell: (cell) => {
          return (
            <Button
              size="sm"
              onClick={() => {
                const vendor = signedVendors.find((vendor) => vendor.vendorBrId === cell.row.original.vendorBrId);

                if (!vendor) {
                  return;
                }

                const assignmentRequest = createAssignmentRequest(cell.row.original, vendor);

                updateAssignmentRequest(assignmentRequest);
              }}
            >
              <BsPencilFill />
            </Button>
          );
        }
      }),
      columnHelper.display({
        header: "Inaktivera tilldelning",
        meta: {
          align: "center"
        },
        cell: (cell) => {
          return (
            <Button
              size="sm"
              variant="danger"
              onClick={() => {
                const vendor = signedVendors.find((vendor) => vendor.vendorBrId === cell.row.original.vendorBrId);

                if (!vendor) {
                  return;
                }

                const assignmentRequest = createAssignmentRequest(cell.row.original, vendor);

                // Using catch() instead of try/catch because the latter requires an await which is unnecessary
                deactivateAssignment(brId, assignmentRequest).catch((error) => {
                  dispatchError(error);
                });
              }}
            >
              <BsXLg />
            </Button>
          );
        }
      })
    ],
    []
  );

  return (
    <RtTable
      bootstrapTableProps={{
        responsive: true,
        striped: true
      }}
      useReactTableProps={{
        data: assignments,
        columns
      }}
    />
  );
}
