import { useEffect, useMemo, useState } from "react";
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";

import type { RTWrappeReturnProps, RTWrapperProps } from "./Interfaces/Interfaces";
import type {
  ColumnDef,
  ColumnFilter,
  ColumnOrderState,
  ColumnPinningState,
  ColumnSort,
  ExpandedState,
  GroupingState,
  PaginationState,
  RowSelectionState,
  VisibilityState
} from "@tanstack/react-table";

import RtCell from "./Cells/RtCell";
import RtHeaderCell from "./Cells/RtHeaderCell";
import RtColumnCheckbox from "./Columns/RtColumnCheckbox";
import RtColumnExpander from "./Columns/RtColumnExpander";
import defaultColumnObject from "./Defaults/Defaults";
import RenderPagination from "./Helpers/RenderPagination";
import RenderTableStateDebug from "./Helpers/RenderTableStateDebug";
import { getHasExpandableRows } from "./Utils";

/**
 * Wrapper for react-table
 *
 * @export
 * @template T - Type of objects the data list containts
 * @param {RTWrapperProps<T>} prop
 * @return {*}  {RTWrappeReturnProps<T>}
 */
export default function useReactTableWrapper<T>({
  columnsVisibility: columnVisibilityProp,
  columnsOrder: columnOrderProp,
  columnsSort,
  selectedRows: selectedRowsProp,
  defaults,
  enableSelection,
  grouping: columnGroupingProp,
  enablePagination,
  renderSubRowComponent,
  disableGroupByRowCount,
  useReactTableProps
}: RTWrapperProps<T>): RTWrappeReturnProps<T> {
  const [grouping, setGrouping] = useState<GroupingState>(columnGroupingProp ?? []);
  const [sorting, setSorting] = useState<ColumnSort[]>(columnsSort ?? []);
  const [data, setData] = useState<T[]>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>([]);
  const [globalFilter, setGlobalFilter] = useState<string | undefined>("");
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(selectedRowsProp ?? {});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(columnOrderProp ?? []);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(columnVisibilityProp ?? {});
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({ left: ["custom-selection", "custom-expander"] });
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: enablePagination?.pageIndex ?? 0,
    pageSize: enablePagination?.pageSize ?? 10
    // If we wanted to control the pageCount, we could provide it here (eg. if we were doing server-side pagination)
  });

  const hasExpandableRows = getHasExpandableRows(grouping, renderSubRowComponent, useReactTableProps?.getSubRows, data);

  const internalColumns: ColumnDef<T>[] = useMemo(
    () => [
      ...(enableSelection ? [RtColumnCheckbox<T>(defaults?.renderCheckboxComponent)] : []),
      ...(hasExpandableRows ? [RtColumnExpander<T>(defaults?.expandColumnIcons)] : [])
    ],
    [enableSelection, hasExpandableRows, defaults?.renderCheckboxComponent, defaults?.expandColumnIcons]
  );

  const columns = internalColumns.length > 0 ? [...internalColumns, ...(useReactTableProps?.columns ?? [])] : useReactTableProps?.columns ?? [];

  useEffect(() => {
    setData(useReactTableProps?.data ?? []);
  }, [useReactTableProps?.data]);

  const defaultColumnMerged = defaults?.defaultColumn ? { ...defaultColumnObject<T>(), ...defaults.defaultColumn } : defaultColumnObject<T>();

  const instance = useReactTable({
    ...useReactTableProps,
    autoResetExpanded: false,
    autoResetPageIndex: false,
    data,
    columns,
    getRowCanExpand: renderSubRowComponent ? () => true : undefined,
    defaultColumn: defaultColumnMerged,
    columnResizeMode: "onEnd",
    pageCount: enablePagination?.pageCount ?? undefined, // allows the table to calculate the page count for us via instance.getPageCount()
    state: {
      sorting,
      columnFilters,
      globalFilter,
      rowSelection,
      columnOrder,
      columnVisibility,
      pagination,
      expanded: hasExpandableRows ? expanded : undefined,
      grouping,
      columnPinning
    },
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value
              };
            }

            return row;
          })
        );
      }
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    onGroupingChange: setGrouping,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    onColumnPinningChange: setColumnPinning,

    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getPaginationRowModel: enablePagination ? getPaginationRowModel() : undefined,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),

    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  });

  return {
    reactTableInstance: instance,
    helperProps: {
      isSorted: sorting.length > 0,
      hasFilter: columnFilters.length > 0 || (globalFilter !== undefined && globalFilter !== ""),
      resetAllFilters: () => {
        instance.resetGlobalFilter();
        instance.resetColumnFilters();
      },
      RenderHeaderWrapper: (props) => <RtHeaderCell {...props} sortColumnIcons={defaults?.sortColumnIcons} />,
      RenderCellWrapper: (props) => <RtCell {...props} disableGroupByRowCount={disableGroupByRowCount} />,
      RenderTableStateDebug: () => <RenderTableStateDebug instance={instance} />,
      RenderPagination: () => <RenderPagination instance={instance} />
    }
  };
}
