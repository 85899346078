import { useEffect, useMemo, useState } from "react";
import { useError } from "@dekiru/react-error-boundary";
import { createColumnHelper } from "@tanstack/react-table";

import type { Agreement, SignedAgreementResponse, Vendor } from "Src/api/Dto";

import { getAgreements, getSignedAgreements } from "Src/api/Publishers";

import RtTable from "Src/components/Common/Table/RtTable";

import useUser from "Src/hooks/useUser";

import { useSquawkApp } from "Src/squawk/StoreApp";

import { CreateEditLink } from "./Agreements/CreateEditLink";
import { ShowAgreementText } from "./Agreements/ShowAgreementText";

const columnHelper = createColumnHelper<Vendor>();

interface State {
  loading: boolean;
  signedAgreements: SignedAgreementResponse[];
  agreements: Agreement[];
}

export default function List() {
  const { allVendors } = useSquawkApp();

  const [{ loading, signedAgreements, agreements }, setState] = useState<State>({
    loading: true,
    signedAgreements: [],
    agreements: []
  });

  const user = useUser();
  const dispatchError = useError();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setState((state) => ({ ...state, loading: true }));
      const [signedAgreements, agreements] = await Promise.all([getSignedAgreements(user.brId), getAgreements(user.brId)]);
      setState((state) => ({ ...state, signedAgreements, agreements, loading: false }));
    } catch (error) {
      dispatchError(error);
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Återförsäljare"
      }),
      columnHelper.display({
        header: "Avall",
        cell: (cell) => {
          const { original: vendor } = cell.row;

          return (
            <CreateEditLink
              mode="create"
              signedAgreement={signedAgreements.find((s) => s.vendorBrId === vendor.vendorBrId)}
              vendor={vendor}
              vendorAgreements={agreements.filter((a) => a.vendorBrId === vendor.vendorBrId)}
            />
          );
        }
      }),
      columnHelper.display({
        header: "Visa avtal",
        meta: {
          align: "center"
        },
        cell: (cell) => {
          const { original: vendor } = cell.row;

          return <ShowAgreementText signedAgreement={signedAgreements.find((s) => s.vendorBrId === vendor.vendorBrId)} vendor={vendor} />;
        }
      }),
      columnHelper.display({
        header: "Egenskaper",
        meta: {
          align: "center"
        },
        cell: (cell) => {
          const { original: vendor } = cell.row;

          return (
            <CreateEditLink
              mode="edit"
              signedAgreement={signedAgreements.find((s) => s.vendorBrId === vendor.vendorBrId)}
              vendor={vendor}
              vendorAgreements={agreements.filter((a) => a.vendorBrId === vendor.vendorBrId)}
            />
          );
        }
      })
    ],
    [signedAgreements, agreements]
  );

  return (
    <RtTable
      fitToContent
      bootstrapTableProps={{
        responsive: true,
        striped: true
      }}
      loading={loading}
      useReactTableProps={{
        data: allVendors,
        columns
      }}
    />
  );
}
